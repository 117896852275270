import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { ItemTag } from "components/ItemTag";
import moment from "moment";
import { dateISOFormat } from "../../../../../constants";
import { ProductItemPreview } from "containers/Projects/components/ProductItemsView/components/ProductItemPreview/ProductItemPreview";
import { HourglassHigh, HourglassLow, HourglassMedium } from "phosphor-react";
import { OverflowTooltip } from "components/OverflowTooltip";
import { NextStepItem } from "./NextStepsTable";
import {
  getItemDeemingDate,
  getItemDueDate,
  getItemNextStepParty,
  getItemNextStepText,
} from "./NextStepsTable.utils";
import { ProductItemTypenameToProduct } from "containers/Projects/Projects.constants";
import { isCompEvent } from "containers/Projects/components/CompEvents/CompEvents.utils";
import { isClaim } from "containers/Projects/components/Claims/Claims.utils";
import { StringCellRendererWithTooltip } from "components/DataGridMiscellaneous/StringCellRendererWithTooltip";

export const useColumns = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return useMemo((): GridColDef<NextStepItem>[] => {
    return [
      {
        field: "project",
        headerName: t("common.labels.project"),
        flex: 0.15,
        sortable: false,
        filterable: false,
        resizable: true,
        valueGetter: (_, row) =>
          row.productInstance.contract.project.friendlyName,
        renderCell: (
          params: GridRenderCellParams<NextStepItem, string, any>
        ) => (
          <StringCellRendererWithTooltip
            value={params.value}
            typographyProps={{ color: theme.palette.grey[700] }}
          />
        ),
      },
      {
        field: "contract",
        headerName: t("common.labels.contract"),
        flex: 0.15,
        sortable: false,
        filterable: false,
        resizable: true,
        valueGetter: (_, row) => row.productInstance.contract.friendlyName,
        renderCell: (
          params: GridRenderCellParams<NextStepItem, string, any>
        ) => <StringCellRendererWithTooltip value={params.value} />,
      },
      {
        field: "nextStep",
        headerName: t("Home.NextSteps.nextStep"),
        flex: 0.2,
        sortable: false,
        filterable: false,
        resizable: true,
        renderCell: (params: GridRenderCellParams<NextStepItem, any, any>) => {
          return (
            <OverflowTooltip
              title={t(getItemNextStepText(params.row) ?? "")}
              typographyProps={{
                variant: "body2",
                fontWeight: 600,
                color: theme.palette.grey[700],
              }}
            />
          );
        },
      },
      {
        field: "regarding",
        headerName: t("common.labels.regarding"),
        flex: 0.1,
        minWidth: 140,
        sortable: false,
        filterable: false,
        resizable: true,
        renderCell: (params) => {
          return (
            <Box display="flex" alignItems="center" width="100%">
              <Stack direction="row" spacing={1} width="100%">
                <ItemTag
                  type={ProductItemTypenameToProduct[params.row.__typename!]}
                />
                <StringCellRendererWithTooltip value={params.row.number} />
              </Stack>
            </Box>
          );
        },
      },
      {
        field: "party",
        headerName: t("common.labels.party"),
        flex: 0.1,
        sortable: false,
        filterable: false,
        resizable: true,
        valueGetter: (_, row) => getItemNextStepParty(row),
        renderCell: (
          params: GridRenderCellParams<NextStepItem, string, any>
        ) => <StringCellRendererWithTooltip value={params.value} />,
      },
      {
        field: "deemingDate",
        headerName: t("Home.NextSteps.deemingDate"),
        width: 120,
        sortable: false,
        filterable: false,
        resizable: false,
        valueGetter: (_, row) => getItemDeemingDate(row),
      },
      {
        field: "dueDate",
        headerName: t("common.labels.dueDate"),
        width: 120,
        sortable: false,
        filterable: false,
        resizable: false,
        renderCell: (params: GridRenderCellParams<NextStepItem, any, any>) => {
          const dueDate = getItemDueDate(params.row);
          if (dueDate) {
            const dateDiff = moment(dueDate).diff(moment.now(), "days");
            const dueDateBetween3And5Days = dateDiff <= 5 && dateDiff > 3;
            const dueDateBetweenInLessThan3Days = dateDiff <= 3 && dateDiff > 0;
            const dueDateToday = dateDiff === 0;
            const overdue = dateDiff < 0;
            return (
              <Tooltip
                title={
                  overdue
                    ? t("Home.NextSteps.dueDateTooltipOverdue", {
                        count: dateDiff,
                      })
                    : undefined
                }
              >
                <Stack gap={1} direction="row" alignItems="center">
                  <Tooltip
                    title={
                      dateDiff > 0
                        ? t("Home.NextSteps.dueDateTooltip", {
                            count: dateDiff,
                          })
                        : dateDiff === 0
                        ? t("Home.NextSteps.dueDateTooltipToday", {
                            count: dateDiff,
                          })
                        : undefined
                    }
                  >
                    <Box display="flex" alignItems="center">
                      {dueDateBetween3And5Days && <HourglassHigh size={16} />}
                      {dueDateBetweenInLessThan3Days && (
                        <HourglassMedium
                          size={16}
                          color={theme.palette.warning.main}
                        />
                      )}
                      {(overdue || dueDateToday) && (
                        <HourglassLow
                          size={16}
                          color={theme.palette.error.main}
                        />
                      )}
                    </Box>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    color={overdue ? "error" : theme.palette.grey[600]}
                  >
                    {moment(dueDate).format(dateISOFormat)}
                  </Typography>
                </Stack>
              </Tooltip>
            );
          }

          return null;
        },
      },
      {
        field: "options",
        headerName: "",
        width: 50,
        resizable: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<NextStepItem, any, any>) => {
          const item = params.row;
          const regardingProductItem =
            isCompEvent(item) || isClaim(item)
              ? item.regardingEvent ?? item.regardingInstruction
              : item.regardingInstruction;

          if (!regardingProductItem) {
            // Note: in theory there will always be an Event or Instruction linked to a CE, but covering for a BE bug for now
            return null;
          }

          return (
            <ProductItemPreview
              includeSeverity={false}
              productItem={regardingProductItem}
            />
          );
        },
      },
    ];
  }, [t, theme]);
};
