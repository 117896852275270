import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  DraftVariationAgreement,
  RecordVariationAgreementNoticeMutation,
  RecordVariationAgreementNoticeMutationVariables,
  SendVariationAgreementNoticeMutation,
  SendVariationAgreementNoticeMutationVariables,
  VariationAgreementNoticePromptQuery,
  VariationAgreementNoticePromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationAgreementNoticeMutation,
  sendVariationAgreementNoticeMutation,
  variationAgreementNoticePromptQuery,
} from "./NotifyAgreementOfVariationAction.query";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";

export const useNotifyAgreementOfVariationAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationAgreementNoticePromptQuery,
    VariationAgreementNoticePromptQueryVariables
  >(variationAgreementNoticePromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationDraftVariationProposals",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAgreementNoticePrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [sendVariationAgreement, { loading: sendVariationAgreementLoading }] =
    useGraphMutation<
      SendVariationAgreementNoticeMutation,
      SendVariationAgreementNoticeMutationVariables
    >(
      sendVariationAgreementNoticeMutation,
      { update: onUpdated },
      triggersAuthWorkflow ? (
        <AuthorizationToastTransMessage />
      ) : (
        t("common.successMessages.entitySent", {
          entity: t("Projects.Variations.variationAgreement"),
        })
      ),
      {
        persist: triggersAuthWorkflow,
      }
    );

  const [
    recordVariationAgreement,
    { loading: recordVariationAgreementLoading },
  ] = useGraphMutation<
    RecordVariationAgreementNoticeMutation,
    RecordVariationAgreementNoticeMutationVariables
  >(
    recordVariationAgreementNoticeMutation,
    { update: onUpdated },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.Variations.variationAgreement"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const draftVariationAgreements = useMemo(
    () =>
      (promptData?.variationAgreementNoticePrompt.draftVariationAgreements ??
        []) as DraftVariationAgreement[],
    [promptData]
  );

  return {
    draftVariationAgreements,
    loading,
    sendVariationAgreement,
    recordVariationAgreement,
    actionLoading:
      sendVariationAgreementLoading || recordVariationAgreementLoading,
  };
};
