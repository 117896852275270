import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { DeleteProductItemDraftConfirmModal } from "containers/Projects/components/DeleteProductItemDraftConfirmModal/DeleteProductItemDraftConfirmModal";
import { useDeleteProductItemDraftConfirmModal } from "containers/Projects/components/DeleteProductItemDraftConfirmModal/useDeleteProductItemDraftConfirmModal";
import { NewProductItemHeader } from "containers/Projects/components/NewProductItemHeader/NewProductItemHeader";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import {
  AddEventItemInput,
  AddRiskItemInput,
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  EditEventItemInput,
  EditRiskItemInput,
  ItemDataInput,
  ProductSchema,
  ProductType,
} from "generated/graphql";
import { useCallbackPrompt } from "hooks/useCallbackPrompt";
import { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { extendedTheme } from "theme/extendedTheme";
import { NewRiskEventItemConfirmModal } from "./components/NewRiskEventItemConfirmModal/NewRiskEventItemConfirmModal";
import { ProductItemDraftConfirmModal } from "../../components/ProductItemDraftConfirmModal";
import { useNewEvent } from "./useNewEvent";
import { useNewRisk } from "./useNewRisk";
import { NewAppPaths } from "helpers/paths/paths";
import { useProductItemContext } from "hooks/useProductItemContext";
import { CommonTabs } from "../../../../constants";
import { SectionContainer } from "components/miscellaneous/SectionContainer";

type SaveDraftContext = {
  isCancel?: boolean;
};

export type NewRiskEventItemProps = {
  isRisk?: boolean;
};

export const NewRiskEventItem: React.FC<NewRiskEventItemProps> = ({
  isRisk = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId, contractId, productInstanceId } = useParams();
  const [searchParams] = useSearchParams();
  const draftId = searchParams.get("draftId");

  const [newlyCreatedRiskEventId, setNewlyCreatedRiskEventId] =
    useState<string>();
  const [newlyCreatedDraftRiskEventId, setNewlyCreatedDraftRiskEventId] =
    useState<string>();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [draftModalVisible, setDraftModalVisible] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const saveDraftContext = useRef<SaveDraftContext>({});
  const [isFormPristine, setIsFormPristine] = useState(true);
  const isEditingDraft = !!draftId;
  const productType = isRisk ? ProductType.RisksRegister : ProductType.Events;

  const [schemaValues, setSchemaValues] = useState<ItemDataInput>({
    sections: [],
  });
  const [schemaValid, setSchemaValid] = useState<boolean>();
  const [enhancedAttachments, setEnhancedAttachments] = useState<
    EnhancedAttachment[]
  >([]);
  const attachmentsToAdd = useRef<AttachmentInput[]>();

  const {
    addRiskItem,
    addRiskItemDraft,
    addRiskItemDraftLoading,
    addRiskItemLoading,
    editRiskItemDraft,
    editRiskItemDraftLoading,
    fetchDraftRiskData,
    fetchDraftRiskDataLoading,
    draftRiskData,
  } = useNewRisk();

  const {
    addEventItem,
    addEventItemDraft,
    addEventItemDraftLoading,
    addEventItemLoading,
    editEventItemDraft,
    editEventItemDraftLoading,
    fetchDraftEventData,
    fetchDraftEventDataLoading,
    draftEventData,
  } = useNewEvent();

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    attachmentsToAdd.current = attachmentsUpdated;
    setIsFormPristine(false);

    if (draftId) {
      await upsertDraftProductItem({
        id: draftId,
        data: schemaValues,
        attachments: attachmentsUpdated,
      });
    }
  };

  const {
    allAttachments,
    attachmentsLoading,
    addAttachments,
    removeAttachment,
    updateAttachment,
    unloadLocalAttachments,
    downloadAttachment,
  } = useAttachments(enhancedAttachments, handleAttachmentsUpdated);

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const {
    showPrompt: showBeforeNavigateAwayModal,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(!isFormPristine);

  const {
    modalVisible: deleteDraftProductItemModalVisible,
    setModalVisible: setDeleteDraftProductItemModalVisible,
    closeModal: closeDeleteDraftProductItemModal,
    handleCancel: handleDeleteDraftProductItemModalCancel,
    handleConfirm: handleDeleteDraftProductItemModalConfirm,
    loading: deleteDraftProductItemLoading,
  } = useDeleteProductItemDraftConfirmModal();

  const {
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
  } = useProductItemContext(projectId!, contractId!, productInstanceId!);

  const triggerDeleteDraftProductItem = () => {
    setDeleteDraftProductItemModalVisible(true);
  };

  const handleDeleteDraftProductItem = async () => {
    await handleDeleteDraftProductItemModalConfirm(draftId!, productType);
    navigate(NewAppPaths.authorized.Projects.path, {
      state: { autoSelectTab: CommonTabs.Drafts },
    });
  };

  const handleCancel = () => {
    if (isFormPristine) {
      navigate(-1);
    } else {
      saveDraftContext.current = {
        isCancel: true,
      };
      setDraftModalVisible(true);
    }
  };

  const handleAddProductItem = useCallback(async () => {
    const computedAttachments = enhancedAttachments.length
      ? attachmentsToAttachmentInputs(enhancedAttachments as Attachment[])
      : attachmentsToAdd.current ?? undefined;

    let data: any;
    let errors;
    if (isRisk) {
      const response = await addRiskItem({
        variables: {
          input: {
            productInstanceId: productInstanceId!,
            data: schemaValues,
            attachments: computedAttachments,
          },
          ...(draftId && { draftId }),
        },
      });
      data = response.data;
      errors = response.errors;
    } else {
      const response = await addEventItem({
        variables: {
          input: {
            productInstanceId: productInstanceId!,
            data: schemaValues,
            attachments: computedAttachments,
          },
          ...(draftId && { draftId }),
        },
      });
      data = response.data;
      errors = response.errors;
    }

    setIsFormPristine(true);
    setConfirmModalVisible(false);

    if (!errors) {
      setNewlyCreatedRiskEventId(
        isRisk ? data!.addRiskItem.id : data!.addEventItem.id
      );
    }
  }, [
    addRiskItem,
    addEventItem,
    isRisk,
    productInstanceId,
    schemaValues,
    draftId,
    attachmentsToAdd,
    enhancedAttachments,
  ]);

  const handleCloseModal = useCallback(() => {
    setConfirmModalVisible(false);
  }, []);

  const handleSaveDraft = async () => {
    if (!isFormSaved) {
      const newDraftId = await upsertDraftProductItem();

      if (newDraftId !== draftId) {
        setNewlyCreatedDraftRiskEventId(newDraftId);
      }
    }
  };

  const handleDraftModalDontSave = () => {
    confirmNavigation(); // for navigate away scenarios

    if (attachmentsToAdd.current?.length) {
      // remove from S3 uploaded files
      unloadLocalAttachments();
    }

    if (saveDraftContext.current.isCancel) {
      setIsFormPristine(true);
      navigate(-1);
    }
  };

  const handleDraftModalConfirm = async () => {
    await upsertDraftProductItem();

    confirmNavigation();
    if (saveDraftContext.current.isCancel) {
      navigate(-1);
    }
  };

  const upsertDraftProductItem = async (
    draftProductItem?:
      | (EditRiskItemInput | AddRiskItemInput)
      | (EditEventItemInput | AddEventItemInput)
  ) => {
    let newDraftId = "";

    if (draftId) {
      // edit draft
      if (isRisk) {
        const { data } = await editRiskItemDraft({
          variables: {
            input: (draftProductItem as EditRiskItemInput) ?? {
              id: draftId,
              data: schemaValues,
              attachments: enhancedAttachments.length
                ? attachmentsToAttachmentInputs(
                    enhancedAttachments as Attachment[]
                  )
                : undefined,
            },
          },
        });
        newDraftId = data?.editDraftRiskItem.id || "";
      } else {
        const { data } = await editEventItemDraft({
          variables: {
            input: (draftProductItem as EditEventItemInput) ?? {
              id: draftId,
              data: schemaValues,
              attachments: enhancedAttachments.length
                ? attachmentsToAttachmentInputs(
                    enhancedAttachments as Attachment[]
                  )
                : undefined,
            },
          },
        });
        newDraftId = data?.editDraftEventItem.id || "";
      }
    } else {
      // add new draft
      if (isRisk) {
        const { data } = await addRiskItemDraft({
          variables: {
            input: (draftProductItem as AddRiskItemInput) ?? {
              productInstanceId: productInstanceId!,
              data: schemaValues,
              attachments: attachmentsToAdd.current,
            },
          },
        });
        newDraftId = data?.addDraftRiskItem.id || "";
      } else {
        const { data } = await addEventItemDraft({
          variables: {
            input: (draftProductItem as AddEventItemInput) ?? {
              productInstanceId: productInstanceId!,
              data: schemaValues,
              attachments: attachmentsToAdd.current,
            },
          },
        });
        newDraftId = data?.addDraftEventItem.id || "";
      }
      attachmentsToAdd.current = [];
    }

    setDraftModalVisible(false);
    setIsFormSaved(true);
    setIsFormPristine(true);

    return newDraftId;
  };

  const handleSchemaValuesChange = useCallback(
    (schemaValues: ItemDataInput) => {
      setIsFormPristine(false);
      setIsFormSaved(false);
      setSchemaValues(schemaValues);
    },
    []
  );

  const handleDraftModalClose = () => {
    cancelNavigation();
    setDraftModalVisible(false);
  };

  const newProductItemName = useMemo(() => {
    const sectionContainingTitle = schemaValues.sections.find((section) =>
      section.entries.find((entry) => entry.name.toLowerCase() === "title")
    );

    return (
      sectionContainingTitle?.entries.find(
        (entry) => entry.name.toLowerCase() === "title"
      )?.value || ""
    );
  }, [schemaValues]);

  useEffect(() => {
    if (draftId) {
      if (isRisk) {
        fetchDraftRiskData({ variables: { id: draftId } });
      } else {
        fetchDraftEventData({ variables: { id: draftId } });
      }
    }
  }, [draftId, fetchDraftRiskData, fetchDraftEventData, isRisk]);

  useEffect(() => {
    if (isRisk) {
      if (draftRiskData) {
        setSchemaValues(draftRiskData.draftRiskItem.data);
        setEnhancedAttachments(
          (draftRiskData.draftRiskItem.attachments as Attachment[]).filter(
            (attach) => attach.status === AttachmentStatus.Active
          ) ?? []
        );
      }
    } else {
      if (draftEventData) {
        setSchemaValues(draftEventData.draftEventItem.data);
        setEnhancedAttachments(
          (draftEventData.draftEventItem.attachments as Attachment[]).filter(
            (attach) => attach.status === AttachmentStatus.Active
          ) ?? []
        );
      }
    }
  }, [draftRiskData, draftEventData, isRisk]);

  useEffect(() => {
    if (newlyCreatedRiskEventId && isFormPristine) {
      if (isRisk) {
        navigate(
          NewAppPaths.authorized.Projects.children.RiskDetails.pathConstructor(
            productInstanceId!,
            newlyCreatedRiskEventId,
            ProductType.RisksRegister
          ),
          { replace: true }
        );
      } else {
        navigate(
          NewAppPaths.authorized.Projects.children.EventDetails.pathConstructor(
            productInstanceId!,
            newlyCreatedRiskEventId,
            ProductType.Events
          ),
          { replace: true }
        );
      }
    }
  }, [
    newlyCreatedRiskEventId,
    isRisk,
    navigate,
    productInstanceId,
    isFormPristine,
  ]);

  useEffect(() => {
    if (newlyCreatedDraftRiskEventId && isFormPristine) {
      navigate(
        { search: `draftId=${newlyCreatedDraftRiskEventId}` },
        { replace: true }
      );
    }
  }, [newlyCreatedDraftRiskEventId, navigate, isFormPristine]);

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        contractTimezone={contractDataLite?.timeZone}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <DeleteProductItemDraftConfirmModal
        open={deleteDraftProductItemModalVisible}
        onClose={closeDeleteDraftProductItemModal}
        onPrimaryClick={handleDeleteDraftProductItem}
        onSecondaryClick={handleDeleteDraftProductItemModalCancel}
        primaryBtnLoading={deleteDraftProductItemLoading}
      />
      <NewRiskEventItemConfirmModal
        open={confirmModalVisible}
        projectName={projectDataLite?.name || ""}
        contractName={contractDataLite?.name || ""}
        productType={productType}
        itemName={newProductItemName}
        primaryBtnLoading={addRiskItemLoading || addEventItemLoading}
        onClose={handleCloseModal}
        onPrimaryClick={handleAddProductItem}
        onSecondaryClick={handleCloseModal}
      />
      <ProductItemDraftConfirmModal
        productItemType={productType}
        open={draftModalVisible || showBeforeNavigateAwayModal}
        secondaryBtnCaption={t("common.buttons.dontSave")}
        primaryBtnCaption={t("common.buttons.save")}
        primaryBtnLoading={
          addRiskItemDraftLoading ||
          editRiskItemDraftLoading ||
          addEventItemDraftLoading ||
          editEventItemDraftLoading
        }
        onClose={handleDraftModalClose}
        onPrimaryClick={handleDraftModalConfirm}
        onSecondaryClick={handleDraftModalDontSave}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <NewProductItemHeader
            productType={productType}
            onCancel={handleCancel}
            onBack={handleCancel}
            onSend={() => {
              setConfirmModalVisible(true);
            }}
            onSaveDraft={handleSaveDraft}
            onDiscardDraft={triggerDeleteDraftProductItem}
            upsertDraftProductItemLoading={
              addRiskItemDraftLoading ||
              editRiskItemDraftLoading ||
              addEventItemDraftLoading ||
              editEventItemDraftLoading
            }
            disabled={!schemaValid || attachmentsLoading}
            saveBtnDisabledTooltip={
              !schemaValid
                ? t("common.labels.invalidForm")
                : attachmentsLoading
                ? t("Attachments.loadingTooltip")
                : undefined
            }
            saved={isFormSaved}
            editMode={isEditingDraft}
          />
          <Box mt={3}>
            {productInstanceDataLoading ||
            fetchDraftRiskDataLoading ||
            fetchDraftEventDataLoading ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode
                productItemType={productType}
                schema={productInstanceData!.productSchema as ProductSchema}
                schemaValues={schemaValues}
                onSchemaValuesChange={handleSchemaValuesChange}
                onSchemaValidityChange={setSchemaValid}
                contractCurrency={contractDataLite?.valueCurrency ?? ""}
                contractTimezone={contractDataLite?.timeZone ?? ""}
                productInstanceId={productInstanceData!.id}
                mainColumnExtraWidgets={
                  <Grid item xs={12} key={"attachments"} position="relative">
                    <SectionContainer>
                      <Attachments
                        editMode
                        attachments={allAttachments}
                        onAttachmentsAdd={addAttachments}
                        onAttachmentRemove={removeAttachment}
                        onAttachmentUpdate={updateAttachment}
                        onAttachmentClick={handleAttachmentClick}
                      />
                    </SectionContainer>
                  </Grid>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
