import {
  Box,
  Breadcrumbs,
  Collapse,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList, ListItem } from "components/DetailsList";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { FormPublicApi } from "decl";
import {
  AddContractBindingInput,
  ChangeContractBindingStatusMutation,
  ChangeContractBindingStatusMutationVariables,
  ContractBinding,
  ContractBindingQuery,
  ContractBindingQueryVariables,
  ContractBindingStatus,
  ContractLiteQuery,
  ContractLiteQueryVariables,
  EditContractBindingInput,
  EditContractBindingMutation,
  EditContractBindingMutationVariables,
  GetProjectByIdLiteQuery,
  GetProjectByIdLiteQueryVariables,
  User,
} from "generated/graphql";
import { changeContractBindingStatusMutation } from "graphql/mutations/changeContractBindingStatus";
import { contractLiteQuery } from "graphql/queries/contractLite.query";
import { getProjectByIdLiteQuery } from "graphql/queries/projectByIdLite.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { UsersThree } from "phosphor-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ContractBindingForm } from "../../components/ContractBindingForm/ContractBindingForm";
import { useContractBindingForm } from "../../components/ContractBindingForm/useContractBindingForm";
import {
  contractBindingQuery,
  editContractBindingMutation,
} from "./ContractBindingDetails.query";
import { getUserName, getUserNameOrEmail } from "helpers/miscelaneous";

export const ContractBindingDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { projectId, contractId, bindingId } = useParams();
  const bindingFormApiRef = useRef<FormPublicApi>(null);

  const updatedBindingDetails = useRef<EditContractBindingInput>();
  const [showDetails, setShowDetails] = useState(true);
  const [readOnly, setReadOnly] = useState(true);

  const {
    data: bindingData,
    refetch: refetchBindingData,
    loading: getBindingDataLoading,
  } = useGraphQuery<ContractBindingQuery, ContractBindingQueryVariables>(
    contractBindingQuery,
    {
      variables: { id: bindingId! },
    }
  );

  const [updatedBindingStatus, setUpdatedBindingStatus] = useState<
    ContractBindingStatus | undefined
  >(bindingData?.contractBinding.status);

  const { data: projectDataLite, loading: getProjectDataLiteLoading } =
    useGraphQuery<GetProjectByIdLiteQuery, GetProjectByIdLiteQueryVariables>(
      getProjectByIdLiteQuery,
      {
        variables: {
          id: projectId!,
        },
      }
    );

  const { data: contractDataLite, loading: getContractDataLiteLoading } =
    useGraphQuery<ContractLiteQuery, ContractLiteQueryVariables>(
      contractLiteQuery,
      {
        variables: { id: contractId! },
      }
    );

  const [
    changeContractBindingStatus,
    { loading: changeContractBindingStatusLoading },
  ] = useGraphMutation<
    ChangeContractBindingStatusMutation,
    ChangeContractBindingStatusMutationVariables
  >(
    changeContractBindingStatusMutation,
    {
      update: (cache) => {
        refetchBindingData();

        cache.evict({ id: "ROOT_QUERY", fieldName: "contract" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "productInstance" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.binding"),
    })
  );

  const {
    companies,
    companyUsers,
    setSelectedCompanyId,
    loading: formDataLoading,
    usersLoading,
    bindingTypes,
  } = useContractBindingForm(contractDataLite?.contract.contractTypeId);

  const [editBinding, { loading: editBindingLoading }] = useGraphMutation<
    EditContractBindingMutation,
    EditContractBindingMutationVariables
  >(
    editContractBindingMutation,
    {
      update: (cache) => {
        refetchBindingData();

        cache.evict({ id: "ROOT_QUERY", fieldName: "project" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "productInstance" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.binding"),
    })
  );

  const handleBreadcrumbClick = (parent?: "contract" | "project") => {
    if (parent === "contract") {
      navigate(-1);
    } else if (parent === "project") {
      navigate(-2);
    } else {
      navigate(-3);
    }
  };

  const handleBindingStatusChange = useCallback(
    async (newStatus: ContractBindingStatus) => {
      await changeContractBindingStatus({
        variables: {
          id: bindingData!.contractBinding.id,
          status: newStatus,
        },
      });
    },
    [changeContractBindingStatus, bindingData]
  );

  const handleCancelClick = useCallback(() => {
    bindingFormApiRef.current?.reset();
    setReadOnly(true);
  }, []);

  const handleEditClick = useCallback(() => {
    setReadOnly(false);
    setShowDetails(true);
  }, []);

  const updateBindingDetails = useCallback(async () => {
    if (
      !updatedBindingDetails.current ||
      !bindingFormApiRef.current?.validate()
    ) {
      return;
    }

    const { errors } = await editBinding({
      variables: {
        input: updatedBindingDetails.current,
      },
    });

    if (!errors) {
      setReadOnly(true);
    }
  }, [editBinding]);

  const handleSaveClick = useCallback(() => {
    updateBindingDetails();

    if (
      updatedBindingStatus &&
      updatedBindingStatus !== bindingData?.contractBinding.status
    ) {
      handleBindingStatusChange(updatedBindingStatus);
    }
  }, [
    updateBindingDetails,
    updatedBindingStatus,
    handleBindingStatusChange,
    bindingData,
  ]);

  const handleFormChange = useCallback(
    (data: AddContractBindingInput | EditContractBindingInput) => {
      if (data.companyId !== updatedBindingDetails.current?.companyId) {
        setSelectedCompanyId(data.companyId);
      }
      updatedBindingDetails.current = data as EditContractBindingInput;
    },
    [setSelectedCompanyId]
  );

  const creatorStr = useMemo(() => {
    if (
      bindingData?.contractBinding.creator.firstname &&
      bindingData?.contractBinding.creator.surname
    ) {
      return getUserName(bindingData.contractBinding.creator as User);
    }

    return "";
  }, [bindingData]);

  const bindingEntityObj = useMemo(() => {
    const toReturn: ListItem[] = [];

    if (!bindingData?.contractBinding) {
      return toReturn;
    }

    return [
      {
        id: "company",
        label: t("common.labels.company"),
        value: bindingData.contractBinding.company.tradingName,
      },
      {
        id: "contractBindingTypeName",
        label: t("common.labels.bindingType"),
        value: bindingData.contractBinding.contractBindingType.description,
      },
      {
        id: "representativeName",
        label: t("AdminConsole.Bindings.labels.representative"),
        value: bindingData.contractBinding.representative
          ? getUserNameOrEmail(
              bindingData.contractBinding.representative as User
            )
          : "",
      },
      {
        id: "defaultCompany",
        label: t("AdminConsole.Bindings.labels.defaultCompany"),
        value: String(!!bindingData.contractBinding.defaultCompany),
      },
      {
        id: "billTo",
        label: t("AdminConsole.Bindings.labels.billedTo"),
        value: String(!!bindingData.contractBinding.isCompanyBilled),
      },
      {
        id: "status",
        label: t("common.labels.status"),
        value: bindingData.contractBinding.status,
      },
    ];
  }, [bindingData?.contractBinding, t]);

  return (
    <Box>
      <EntityDetailsHeader
        loading={getBindingDataLoading || changeContractBindingStatusLoading}
        title={t("common.labels.contractBinding")}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={() => handleBreadcrumbClick()}>
              <Typography variant="body2">
                {t("AdminConsole.Projects.labels.projects")}
              </Typography>
            </Link>
            <Link key="2" onClick={() => handleBreadcrumbClick("project")}>
              <Typography variant="body2">
                {projectDataLite?.project.friendlyName}
              </Typography>
            </Link>
            <Link key="3" onClick={() => handleBreadcrumbClick("contract")}>
              <Typography variant="body2">
                {contractDataLite?.contract.friendlyName}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        creator={creatorStr}
        dateCreated={bindingData?.contractBinding.dateCreated}
      />
      <CollapsibleHeader
        title={t("AdminConsole.Bindings.labels.contractBindingDetails")}
        collapsed={!showDetails}
        icon={
          <UsersThree
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        editMode={!readOnly}
        onCancel={handleCancelClick}
        onEdit={handleEditClick}
        onSave={handleSaveClick}
        onSaveActionLoading={editBindingLoading}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList
              loading={getBindingDataLoading}
              entity={bindingEntityObj}
            />
          ) : getBindingDataLoading ? null : (
            <ContractBindingForm
              contractBinding={bindingData?.contractBinding as ContractBinding}
              onChange={handleFormChange}
              onStatusChange={setUpdatedBindingStatus}
              apiRef={bindingFormApiRef}
              contractId={contractId!}
              companies={companies || []}
              bindingTypes={bindingTypes || []}
              companyUsers={companyUsers || []}
              usersSelectDisabled={usersLoading}
              disabled={
                formDataLoading ||
                getProjectDataLiteLoading ||
                getContractDataLiteLoading ||
                editBindingLoading
              }
            />
          )}
        </PageContentContainer>
      </Collapse>
    </Box>
  );
};
