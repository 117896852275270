import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  dateTimeISOFormat,
  temporaryRowId,
} from "../../../../../../../../constants";
import {
  AddEffectOptionInput,
  EffectEstimationType,
  EffectOption,
  EffectOptionStatus,
  User,
} from "generated/graphql";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { useColumns } from "./EffectPresetOptions.constants";
import moment from "moment";
import { exportToExcel } from "helpers/exportToExcel";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { ListBullets } from "phosphor-react";
import { getUserName } from "helpers/miscelaneous";

export type EffectPresetOptionsProps = {
  effectPresetId: string;
  effectOptions: EffectOption[];
  loading?: boolean;
  onStatusChange: (
    effectPreset: EffectOption,
    newStatus: EffectOptionStatus
  ) => void;
  onAdd: (input: AddEffectOptionInput) => any;
};

export const EffectPresetOptions: React.FC<EffectPresetOptionsProps> = ({
  effectPresetId,
  effectOptions,
  loading,
  onStatusChange,
  onAdd,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser } = useContext(GlobalContext);
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<EffectOptionStatus>[];

  const [rows, setRows] = useState<EffectOption[]>(effectOptions);
  const [showEffectPresets, setShowEffectPresets] = useState(true);
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "dateCreated", sort: "desc" },
  ]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(rows)
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [EffectOptionStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name", width: 20 },
      { header: t("common.labels.status"), key: "status", width: 20 },
      {
        header: "Contract type default",
        key: "isDefault",
        width: 20,
      },
      {
        header: "Contract type",
        key: "contractTypeId",
        width: 20,
      },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
      { header: t("common.labels.createdBy"), key: "creator", width: 20 },
    ];

    const rowsToExport = effectOptions
      .filter(
        (effectOption) => (selectionModel || []).indexOf(effectOption.id) >= 0
      )
      .map((effectOption) => {
        return {
          ...effectOption,
          dateCreated: effectOption.dateCreated
            ? new Date(effectOption.dateCreated)
            : "",
          creator: getUserName(effectOption.creator),
        };
      });

    exportToExcel(
      t("AdminConsole.Effect.labels.effectPresetOptions"),
      columns,
      rowsToExport
    );
  };

  const handleStatusChange = useCallback(
    (row: EffectOption, newStatus: EffectOptionStatus) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                status: newStatus,
              };
            }

            return row;
          })
        );
      } else {
        onStatusChange(row, newStatus);
      }
    },
    [onStatusChange]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onStatusChange(
          rows.find((role) => role.id === rowId)!,
          EffectOptionStatus.Removed
        );
      }
    },
    [onStatusChange, rows]
  );

  const handleEstimationTypeChange = useCallback(
    (row: EffectOption, newEstimationType: EffectEstimationType) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                estimationType: newEstimationType,
              };
            }

            return row;
          })
        );
      }
    },
    []
  );

  const handleRowChangesCommited = useCallback(
    async (
      newRow: GridRowModel<EffectOption>,
      _: GridRowModel<EffectOption>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (
          newRow.label &&
          (newRow.estimationType === EffectEstimationType.Decimal
            ? !!newRow.unit
            : true)
        ) {
          const success = await onAdd({
            label: newRow.label,
            effectPresetId: newRow.effectPresetId,
            estimationType: newRow.estimationType,
            unit: newRow.unit,
          });

          if (!success) {
            // remove temporary row
            setRows((curRows) =>
              curRows.filter((curRow) => curRow.id !== temporaryRowId)
            );
          }
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "label",
            },
          }));
        }
      }

      return newRow;
    },
    [onAdd]
  );

  // TODO: export these functions in generic hook
  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        id: temporaryRowId,
        effectPresetId,
        estimationType: EffectEstimationType.None,
        isDefault: false,
        isInternal: false,
        name: "",
        options: { items: [] },
        status: EffectOptionStatus.Active,
        label: "",
        unit: "",
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "label",
        },
      }));
    });
  };

  const columns = useColumns(
    statusOptions,
    handleStatusChange,
    rowModesModel,
    handleRowSaveClick,
    handleDeleteRow,
    handleEstimationTypeChange
  );

  useEffect(() => {
    setRows(effectOptions);
    setRowModesModel(computeGridRowModes(effectOptions));
  }, [effectOptions]);

  // const tempRecordAdded = !!rows.find((row) => row.id === temporaryRowId);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Effect.labels.effectPresetOptions")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <ListBullets
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowEffectPresets((state) => !state)}
        collapsed={!showEffectPresets}
      />
      <Collapse in={showEffectPresets}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows || []}
            columns={columns}
            getRowId={(rowData: EffectOption) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            rowModesModel={rowModesModel}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            getCellClassName={(
              params: GridCellParams<any, EffectOption, any>
            ) => {
              return params.row.status === EffectOptionStatus.Removed
                ? "greyed-out"
                : "";
            }}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            // experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
