import {
  RecordVariationFurtherParticularsRequestMutation,
  RecordVariationFurtherParticularsRequestMutationVariables,
  SendVariationFurtherParticularsRequestMutation,
  SendVariationFurtherParticularsRequestMutationVariables,
  VariationFurtherParticularsRequestPromptQuery,
  VariationFurtherParticularsRequestPromptQueryVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  recordVariationFurtherParticularsRequestMutation,
  sendVariationFurtherParticularsRequestMutation,
  variationFurtherParticularsRequestPromptQuery,
} from "./RequestFurtherParticularsAction.query";
import { OnMutationDone } from "decl";
import { useGraphQuery } from "hooks/useGraphQuery";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useRequestFurtherParticularsAction = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationHistoryItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationDetailsExplorerItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationAction",
    });
    cache.gc();
    onChange?.();
  };

  const { data: promptData, loading } = useGraphQuery<
    VariationFurtherParticularsRequestPromptQuery,
    VariationFurtherParticularsRequestPromptQueryVariables
  >(variationFurtherParticularsRequestPromptQuery, {
    variables: {
      input: {
        variationId: variation!.id,
      },
    },
    skip: !variation,
  });

  const [
    sendFurtherParticularsRequest,
    { loading: sendFurtherParticularsRequestLoading },
  ] = useGraphMutation<
    SendVariationFurtherParticularsRequestMutation,
    SendVariationFurtherParticularsRequestMutationVariables
  >(
    sendVariationFurtherParticularsRequestMutation,
    {
      update: handleOnUpdate,
    },
    t("Projects.Variations.successMessages.furtherParticularsRequestSent")
  );

  const [
    recordFurtherParticularsRequest,
    { loading: recordFurtherParticularsRequestLoading },
  ] = useGraphMutation<
    RecordVariationFurtherParticularsRequestMutation,
    RecordVariationFurtherParticularsRequestMutationVariables
  >(
    recordVariationFurtherParticularsRequestMutation,
    {
      update: handleOnUpdate,
    },
    t("Projects.Variations.successMessages.furtherParticularsRequestRecord")
  );

  return {
    reasons: promptData?.variationFurtherParticularsRequestPrompt.reasons,
    loading,
    actionLoading:
      sendFurtherParticularsRequestLoading ||
      recordFurtherParticularsRequestLoading,
    sendFurtherParticularsRequest,
    recordFurtherParticularsRequest,
  };
};
