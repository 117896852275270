import { debounce } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateSendVariationErrorDeterminationDisagreementPreviewMutation,
  GenerateSendVariationErrorDeterminationDisagreementPreviewMutationVariables,
  SendVariationErrorDeterminationDisagreementPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateSendVariationErrorDeterminationDisagreementPreviewMutation } from "./DisagreeWithNoticeOfErrorInDeterminationAction.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useSendDisagreementRegardingNoticeOfErrorInDeterminationModal = (
  remarks: string
) => {
  const [preview, setPreview] =
    useState<SendVariationErrorDeterminationDisagreementPreview>();

  const { variation, contract } = useContext(VariationWidgetContext);

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationErrorDeterminationDisagreementPreviewMutation,
    GenerateSendVariationErrorDeterminationDisagreementPreviewMutationVariables
  >(
    generateSendVariationErrorDeterminationDisagreementPreviewMutation,
    {},
    null
  );

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
          },
        },
      });

      if (data) {
        setPreview(
          data.generateSendVariationErrorDeterminationDisagreementPreview
        );
      }
    }, [generatePreview, variation, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // TODO: right now we're generating a preview based on the first set of props the component receives. If it was to re-generate every time the props change
    // and knowing how React is working, it would've generated too many preview files which had to be deleted later. If previews turn out not to be accurate,
    // we should instead do a deep equality on the props and remove the old one and generate a new preview only if props are indeed different.

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview: preview,
    contract,
  };
};
