import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { dateISOFormat, dateTimeISOFormat } from "../../../constants";
import moment from "moment";

type DateWithTimeTooltipProps = {
  datetime: string;
  timezone: string;
} & Partial<TypographyProps>;

export const DateWithTimeTooltip: React.FC<DateWithTimeTooltipProps> = ({
  datetime,
  timezone,
  ...restTypographyProps
}) => {
  return (
    <Tooltip title={moment(datetime).tz(timezone).format(dateTimeISOFormat)}>
      <Typography sx={{ cursor: "pointer" }} {...restTypographyProps}>
        {moment(datetime).tz(timezone).format(dateISOFormat)}
      </Typography>
    </Tooltip>
  );
};
