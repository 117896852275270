import {
  AddContractKeyDateInput,
  ContractKeyDate,
  ContractKeyDateStatus,
  EditContractKeyDateInput,
} from "generated/graphql";
import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { StatusOption } from "components/StatusTag/StatusTag";
import { StyledDataGrid } from "components/StyledDataGrid";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumns } from "./ContractKeyDates.constants";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import {
  dateISOFormat,
  dateTimeISOFormat,
  temporaryRowId,
} from "../../../../../../constants";
import { GlobalContext } from "state-management/globalContext/Global.context";
import moment from "moment";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { LineSegments } from "phosphor-react";

export type ContractKeyDatesProps = {
  keyDates: ContractKeyDate[];
  loading?: boolean;
  projectFriendlyName?: string;
  contractFriendlyName?: string;
  onKeyDateAdd: (newKeyDate: AddContractKeyDateInput) => any;
  onKeyDateEdit: (editedKeyDate: EditContractKeyDateInput) => any;
};

export const ContractKeyDates: React.FC<ContractKeyDatesProps> = ({
  keyDates,
  loading,
  projectFriendlyName,
  contractFriendlyName,
  onKeyDateAdd,
  onKeyDateEdit,
}) => {
  const { t } = useTranslation();
  const { authenticatedUser } = useContext(GlobalContext);
  const theme = useTheme();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ContractKeyDateStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [rows, setRows] = useState<ContractKeyDate[]>(keyDates);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(keyDates)
  );
  const [gridVisibility, setGridVisibility] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "number", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ContractKeyDateStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const handleExportContractKeyDatesToExcel = () => {
    const columns = [
      {
        header: t("common.labels.number"),
        key: "number",
        width: 20,
      },
      {
        header: t("AdminConsole.ContractKeyDates.conditionToBeMet"),
        key: "conditionToBeMet",
        width: 20,
      },
      {
        header: t("AdminConsole.ContractKeyDates.keyDate"),
        key: "keyDate",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
    ];

    const rows = keyDates
      .filter((keyDate) => (selectionModel || []).indexOf(keyDate.id) >= 0)
      .map((keyDate) => ({
        ...keyDate,
        keyDate: keyDate.keyDate ? new Date(keyDate.keyDate) : "",
      }));

    exportToExcel(
      `${projectFriendlyName}-${contractFriendlyName}-${t(
        "AdminConsole.ContractKeyDates.contractKeyDates"
      )}`,
      columns,
      rows
    );
  };

  const handleRowChangesCommited = useCallback(
    async (
      newRow: GridRowModel<ContractKeyDate>,
      oldRow: GridRowModel<ContractKeyDate>
    ) => {
      if (!newRow.conditionToBeMet || !newRow.keyDate) {
        setRowModesModel((prevData) => ({
          ...prevData,
          [temporaryRowId]: {
            mode: GridRowModes.Edit,
            fieldToFocus: !newRow.conditionToBeMet
              ? "conditionToBeMet"
              : "keyDate",
          },
        }));
        return newRow;
      }

      if (newRow.id === temporaryRowId) {
        const success = await onKeyDateAdd({
          number: newRow.number,
          conditionToBeMet: newRow.conditionToBeMet,
          keyDate: moment(newRow.keyDate).format(dateISOFormat),
        });

        if (!success) {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "conditionToBeMet",
            },
          }));
        }
      } else if (
        newRow.conditionToBeMet !== oldRow.conditionToBeMet ||
        newRow.keyDate !== oldRow.keyDate ||
        newRow.number !== oldRow.number
      ) {
        onKeyDateEdit({
          id: newRow.id,
          number: newRow.number,
          conditionToBeMet: newRow.conditionToBeMet,
          keyDate: moment(newRow.keyDate).format(dateISOFormat),
          status: newRow.status,
        });
      }

      return newRow;
    },
    [onKeyDateAdd, onKeyDateEdit]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleKeyDateStatusChange = useCallback(
    (row: ContractKeyDate, newStatus: ContractKeyDateStatus) => {
      onKeyDateEdit({
        id: row.id,
        number: row.number,
        conditionToBeMet: row.conditionToBeMet,
        keyDate: moment(row.keyDate).format(dateISOFormat),
        status: newStatus,
      });
    },
    [onKeyDateEdit]
  );

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        const keyDateToUpdate = keyDates.find(
          (keyDate) => keyDate.id === rowId
        )!;

        onKeyDateEdit({
          id: keyDateToUpdate.id,
          number: keyDateToUpdate.number,
          conditionToBeMet: keyDateToUpdate.conditionToBeMet,
          keyDate: moment(keyDateToUpdate.keyDate).format(dateISOFormat),
          status: ContractKeyDateStatus.Removed,
        });
      }
    },
    [onKeyDateEdit, keyDates]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser!,
        creatorId: authenticatedUser!.id,
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        dateModified: moment(new Date().toString()).format(dateTimeISOFormat),
        keyDate: moment(new Date()).add(31, "days").format(dateISOFormat),
        conditionToBeMet: "",
        id: temporaryRowId,
        status: ContractKeyDateStatus.Active,
        contractTypeId: "",
        number: null,
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "number",
        },
      }));
    });
  };

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const columns = useMemo(
    () =>
      getColumns({
        t,
        onStatusChange: handleKeyDateStatusChange,
        handleDeleteRow,
        statusOptions,
        handleSaveRow: handleRowSaveClick,
        rowModesModel,
      }),
    [
      statusOptions,
      t,
      rowModesModel,
      handleRowSaveClick,
      handleDeleteRow,
      handleKeyDateStatusChange,
    ]
  );

  useEffect(() => {
    setRows(keyDates);
    setRowModesModel(computeGridRowModes(keyDates));
  }, [keyDates]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.ContractKeyDates.contractKeyDates")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportContractKeyDatesToExcel}
        onToggleCollapse={() => setGridVisibility((state) => !state)}
        collapsed={!gridVisibility}
        withShadow={false}
        icon={
          <LineSegments
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
      />
      <Collapse in={gridVisibility}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: ContractKeyDate) => rowData.id}
            onRowSelectionModelChange={handleGridRowSelectionModelChange}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, ContractKeyDate, any>
            ) => {
              return params.row.status === ContractKeyDateStatus.Removed
                ? "greyed-out"
                : "";
            }}
            autoHeight
            hideFooter
            checkboxSelection
            disableRowSelectionOnClick
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
