import { dateTimeISOFormat } from "../../../../../../constants";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  AuthorizationWorkflowAudit,
  EarlyWarningItem,
  InstructionItem,
  ProductType,
  User,
} from "generated/graphql";
import moment from "moment";
import {
  datetimeComparatorFn,
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";
import { Box, Typography } from "@mui/material";
import { ItemTag } from "components/ItemTag";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { authorizationAuditStatusOptions } from "containers/Home/components/Authorizations/Authorizations.constants";

export const computeRegardingColumnText = (
  authAudit: AuthorizationWorkflowAudit
) => {
  return [
    ProductType.CompEvents,
    ProductType.DailyDiary,
    ProductType.Claims,
    ProductType.Variations,
  ].indexOf(authAudit.regardingProductType) >= 0
    ? authAudit.regardingProductItem.number
    : [ProductType.EarlyWarnings, ProductType.Instructions].indexOf(
        authAudit.regardingProductType
      ) >= 0
    ? (authAudit.regardingProductItem as EarlyWarningItem | InstructionItem)
        .title
    : `${
        authAudit.regardingProductItem.productInstance.numberingFormat ??
        authAudit.regardingProductItem.productInstance.product.numberingFormat
      }XXXX`;
};

export const useColumns = (): GridColDef<AuthorizationWorkflowAudit>[] => {
  const { t } = useTranslation();
  const dateCreatedCol = useDateCreatedColumnConfig();
  const columns = useMemo(
    () =>
      [
        {
          field: "contract",
          headerName: t("common.labels.contract"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
          filterable: false,
          valueGetter: (_, row) =>
            row.action.productInstance.contract.friendlyName,
        },
        {
          field: "product",
          headerName: t("common.labels.product"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
          filterable: false,
          valueGetter: (_, row) => row.action.productInstance.product.name,
        },
        {
          field: "action",
          headerName: t("AdminConsole.AuthorizationWorkflows.action"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
          filterable: false,
          valueGetter: (_, row) =>
            t(
              `Home.Authorizations.AuthorizeDenyModal.actions.${row.action.productOutputAction.name}`
            ),
        },
        {
          field: "regarding",
          headerName: t("common.labels.regarding"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            const regardingProductItem = computeRegardingColumnText(params.row);

            return (
              <Box display="flex" alignItems="center">
                <ItemTag type={params.row.regardingProductType} />
                <Typography variant="p2" ml={1}>
                  {regardingProductItem}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "workflow",
          headerName: t("AdminConsole.AuthorizationWorkflows.workflow"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
          filterable: false,
          valueGetter: (_, row) => row.workflow.name,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 130,
          resizable: true,
          sortable: false,
          filterable: false,
          sortComparator: statusComparatorFunction,
          renderCell: (params) => {
            return (
              <StatusTagNew
                selectedOptionId={params.row.status}
                disabled
                options={authorizationAuditStatusOptions}
              />
            );
          },
        },
        {
          field: "initiatedBy",
          headerName: t("AdminConsole.AuthorizationAuditTrails.initiatedBy"),
          flex: 0.125,
          minWidth: 150,
          resizable: true,
          sortable: false,
          filterable: false,
          valueFormatter: (value: User) => getUserName(value),
        },
        {
          field: "dateInitiated",
          headerName: t("AdminConsole.AuthorizationAuditTrails.dateInitiated"),
          width: 175,
          resizable: true,
          sortable: false,
          filterable: false,
          sortComparator: datetimeComparatorFn,
          valueGetter: (_, row) => row.dateInitiated,
          valueFormatter: (value: string) => {
            return moment(value).format(dateTimeISOFormat);
          },
        },
        {
          ...dateCreatedCol,
          sortable: false,
          filterable: false,
        },
      ] as GridColDef<AuthorizationWorkflowAudit>[],
    [t, dateCreatedCol]
  );
  return columns;
};
