import {
  css,
  Grid,
  GridProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { ListItemsDivider } from "components/ListItemsDivider";
import { OverflowTooltip } from "components/OverflowTooltip";
import {
  ClaimRecipient,
  CompEventRecipient,
  EarlyWarningRecipient,
  InstructionRecipient,
  NotificationRecipient,
  VariationRecipient,
} from "generated/graphql";
import { Check } from "phosphor-react";
import React from "react";
import { useTranslation } from "react-i18next";

export type RecipientsPreviewProps = {
  recipients:
    | EarlyWarningRecipient[]
    | InstructionRecipient[]
    | CompEventRecipient[]
    | ClaimRecipient[]
    | VariationRecipient[]
    | NotificationRecipient[];
};

const Checkmark: React.FC<{ checked: boolean }> = ({ checked }) => {
  const theme = useTheme();
  return (
    <Check
      size={16}
      color={checked ? theme.palette.primary.main : theme.palette.grey[300]}
    />
  );
};

const GridItem: React.FC<{ children: React.ReactNode } & GridProps> = ({
  children,
  ...restGridProps
}) => {
  return (
    <Grid
      item
      xs={2}
      justifyContent="center"
      alignItems="center"
      display="flex"
      {...restGridProps}
    >
      {children}
    </Grid>
  );
};

const Header: React.FC<{ children: React.ReactNode } & TypographyProps> = ({
  children,
  ...restTypographyProps
}) => {
  return (
    <Typography
      variant="caption3"
      color="grey.500"
      fontWeight={700}
      textTransform="uppercase"
      {...restTypographyProps}
    >
      {children}
    </Typography>
  );
};

const RecipientName: React.FC<
  { children: React.ReactNode } & TypographyProps
> = ({ children, ...restTypographyProps }) => {
  const theme = useTheme();

  return (
    <OverflowTooltip
      title={children}
      typographyProps={{
        variant: "p2",
        color: theme.palette.grey[800],
        ...restTypographyProps,
      }}
    />
  );
};

const HeaderGridItem = styled(GridItem)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    background: ${theme.palette.common.white};
  `
);

export const RecipientsPreview: React.FC<RecipientsPreviewProps> = ({
  recipients,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container maxHeight="400px" overflow="auto">
      <HeaderGridItem xs={8} justifyContent="flex-start" mb={2}>
        <Header>{t("common.labels.name")}</Header>
      </HeaderGridItem>
      <HeaderGridItem xs={2} mb={2}>
        <Header>{t("Settings.preferences.inAppCapitalized")}</Header>
      </HeaderGridItem>
      <HeaderGridItem xs={2} mb={2}>
        <Header>{t("Settings.preferences.email").toLowerCase()}</Header>
      </HeaderGridItem>
      {recipients.map((recipient, index) => (
        <React.Fragment key={recipient.userId}>
          <GridItem xs={8} justifyContent="flex-start">
            <RecipientName>{recipient.name}</RecipientName>
          </GridItem>
          <GridItem xs={2}>
            <Checkmark checked={recipient.preference.inApp} />
          </GridItem>
          <GridItem xs={2}>
            <Checkmark checked={recipient.preference.email} />
          </GridItem>
          {index < recipients.length - 1 && (
            <Grid item xs={12}>
              <ListItemsDivider
                sx={{
                  marginBottom: theme.spacing(1.5),
                  marginTop: theme.spacing(1.5),
                }}
              />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};
