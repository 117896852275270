import {
  GridFilterModel,
  GridLogicOperator,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import { VariationItem } from "generated/graphql";
import { useCallback, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { exportToExcel } from "../../../../../../helpers/exportToExcel";
import { useSearchParams } from "react-router-dom";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { useCEClaimVariationTable } from "hooks/useCEClaimVariationTable";
import { useContractLite } from "hooks/useContractLite";
import { useColumns } from "./VariationsTable.constants";
import { Column } from "exceljs";

export type VariationsTableProps = {
  variations: VariationItem[];
  loading: boolean;
  apiRef?: React.Ref<ProductItemsTablePublicAPI>;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void;
};

export const VariationsTable: React.FC<VariationsTableProps> = ({
  variations,
  loading,
  apiRef,
  selectionModel,
  onSelectionModelChange,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const contractId = searchParams.get("contractId");

  const { handleRowClick, handleClearSelection, handleSelectionModelChange } =
    useCEClaimVariationTable(projectId!, contractId!, onSelectionModelChange);

  const { contractLite } = useContractLite(contractId!);

  const gridApiRef = useGridApiRef();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "lastAction", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = useCallback(async () => {
    if (!variations || !variations.length) {
      console.warn("No variation to export");
      return;
    }

    const columns: Partial<Column>[] = [
      {
        header: t("Projects.Variations.variationNumber"),
        key: "number",
      },
      {
        header: t("common.labels.origin"),
        key: "origin",
      },
      {
        header: t("Projects.Variations.regardingTitle"),
        key: "regardingTitle",
      },
      {
        header: t("Projects.Variations.regardingProduct"),
        key: "regardingProduct",
      },
      {
        header: t("Projects.Variations.sentDate"),
        key: "sentDate",
      },
      {
        header: t("common.labels.status"),
        key: "status",
      },
      {
        header: t("Projects.Variations.priceSought"),
        key: "priceSought",
        numFmt: "#.00",
      },
      {
        header: t("Projects.Variations.eotSought"),
        key: "eotSought",
      },
      {
        header: t("Projects.Variations.priceGranted"),
        key: "priceGranted",
        numFmt: "#.00",
      },
      {
        header: t("Projects.Variations.eotGranted"),
        key: "eotGranted",
      },
    ];

    const rows = variations
      .filter((variation) => (selectionModel || []).indexOf(variation.id) >= 0)
      .map((variation) => ({
        ...variation,
        origin: t(`Projects.Variations.types.${variation.type}`),
        regardingTitle: variation.regardingInstruction?.title,
        regardingProduct: t("Projects.Instructions.instructions"),
        sentDate: new Date(variation.dateCreated),
        priceSought:
          typeof variation.priceSought === "number"
            ? variation.priceSought
            : "-",
        eotSought:
          typeof variation.timeSought === "number"
            ? Math.abs(variation.timeSought)
            : "-",
        priceGranted:
          typeof variation.priceGranted === "number"
            ? variation.priceGranted
            : "-",
        eotGranted:
          typeof variation.timeGranted === "number"
            ? Math.abs(variation.timeGranted)
            : "-",
      }));

    exportToExcel(t("Projects.Variations.variations"), columns, rows);
  }, [variations, selectionModel, t]);

  useImperativeHandle(
    apiRef,
    () => ({
      clearSelection: handleClearSelection,
      exportToExcel: handleExportToExcel,
    }),
    [handleClearSelection, handleExportToExcel]
  );

  const columns = useColumns(contractLite);

  return (
    <NewStyledDataGrid
      apiRef={gridApiRef}
      rows={variations || []}
      columns={columns}
      getRowId={(rowData: VariationItem) => rowData.id}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      loading={loading}
      sortingMode="client"
      sortModel={sortingModel}
      onSortModelChange={setSortingModel}
      filterMode="client"
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      onRowClick={handleRowClick}
      checkboxSelection
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
