import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { dateTimeISOFormat } from "../../../../../../constants";
import {
  AuthorizationWorkflow,
  AuthorizationWorkflowStatus,
} from "generated/graphql";
import moment from "moment";
import {
  datetimeComparatorFn,
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  statusOptions: StatusOption<AuthorizationWorkflowStatus>[],
  onStatusChange: (
    row: AuthorizationWorkflow,
    newStatus: AuthorizationWorkflowStatus
  ) => void
): GridColDef<AuthorizationWorkflow>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const columns = useMemo(
    () =>
      [
        {
          field: "name",
          headerName: t("common.labels.name"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, AuthorizationWorkflow, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                disabled // TODO: re-enable when BE is ready to delete workflows which are in use
                onChange={(
                  newStatus: StatusOption<AuthorizationWorkflowStatus>
                ) => onStatusChange(params.row, newStatus.id)}
              />
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => getUserName(row.creator),
        },
        {
          field: "dateModified",
          headerName: t("common.labels.dateModified"),
          width: 175,
          resizable: true,
          sortComparator: datetimeComparatorFn,
          valueFormatter: (value: string) => {
            return moment(value).format(dateTimeISOFormat);
          },
        },
        {
          field: "modifiedBy",
          headerName: t("common.labels.modifiedBy"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => getUserName(row.modifiedBy),
        },
      ] as GridColDef<AuthorizationWorkflow>[],
    [t, dateCreatedColConfig, statusOptions, onStatusChange]
  );
  return columns;
};
