import { OnMutationDone } from "decl";
import {
  AddDraftAssessmentMutation,
  AddDraftAssessmentMutationVariables,
  AddDraftQuotationMutation,
  AddDraftQuotationMutationVariables,
  EditDraftAssessmentMutation,
  EditDraftAssessmentMutationVariables,
  EditDraftQuotationMutation,
  EditDraftQuotationMutationVariables,
  RemoveDraftAssessmentMutation,
  RemoveDraftAssessmentMutationVariables,
  RemoveDraftQuotationMutation,
  RemoveDraftQuotationMutationVariables,
} from "generated/graphql";
import { addDraftAssessmentMutation } from "graphql/mutations/addDraftAssessment";
import { addDraftQuotationMutation } from "graphql/mutations/addDraftQuotation";
import { editDraftAssessmentMutation } from "graphql/mutations/editDraftAssessment";
import { editDraftQuotationMutation } from "graphql/mutations/editDraftQuotation";
import { removeDraftAssessmentMutation } from "graphql/mutations/removeDraftAssessment";
import { removeDraftQuotationMutation } from "graphql/mutations/removeDraftQuotation";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompEventWidgetContext } from "../../CompEvents/CompEventWidget/CompEventWidget.context";

export const useCEQuotationAssessmentModal = () => {
  const { compEvent } = useContext(CompEventWidgetContext);
  const { t } = useTranslation();

  const [modalVisibility, setModalVisibility] = useState(false);

  const toggleModalVisibility = () => {
    setModalVisibility((state) => !state);
  };

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventQuotationPrompt",
        args: { input: { compEventId: compEvent!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventOwnAssessmentPrompt",
        args: { input: { compEventId: compEvent!.id } },
      });
      cache.gc();
    },
    [compEvent]
  );

  const [addDraftQuotation, { loading: addDraftQuotationLoading }] =
    useGraphMutation<
      AddDraftQuotationMutation,
      AddDraftQuotationMutationVariables
    >(
      addDraftQuotationMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.CompEvents.draftQuotation"),
      })
    );

  const [addDraftAssessment, { loading: addDraftAssessmentLoading }] =
    useGraphMutation<
      AddDraftAssessmentMutation,
      AddDraftAssessmentMutationVariables
    >(
      addDraftAssessmentMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.CompEvents.draftAssessment"),
      })
    );

  const [editDraftQuotation, { loading: editDraftQuotationLoading }] =
    useGraphMutation<
      EditDraftQuotationMutation,
      EditDraftQuotationMutationVariables
    >(
      editDraftQuotationMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.CompEvents.draftQuotation"),
      })
    );

  const [editDraftAssessment, { loading: editDraftAssessmentLoading }] =
    useGraphMutation<
      EditDraftAssessmentMutation,
      EditDraftAssessmentMutationVariables
    >(
      editDraftAssessmentMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.CompEvents.draftAssessment"),
      })
    );

  const [removeDraftQuotation, { loading: removeDraftQuotationLoading }] =
    useGraphMutation<
      RemoveDraftQuotationMutation,
      RemoveDraftQuotationMutationVariables
    >(
      removeDraftQuotationMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.CompEvents.draftQuotation"),
      })
    );

  const [removeDraftAssessment, { loading: removeDraftAssessmentLoading }] =
    useGraphMutation<
      RemoveDraftAssessmentMutation,
      RemoveDraftAssessmentMutationVariables
    >(
      removeDraftAssessmentMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.CompEvents.draftAssessment"),
      })
    );

  return {
    modalVisibility,
    toggleModalVisibility,
    addDraftQuotation,
    addDraftAssessment,
    loading:
      addDraftQuotationLoading ||
      editDraftQuotationLoading ||
      removeDraftQuotationLoading ||
      addDraftAssessmentLoading ||
      editDraftAssessmentLoading ||
      removeDraftAssessmentLoading,
    addDraftQuotationLoading,
    addDraftAssessmentLoading,
    editDraftQuotation,
    editDraftAssessment,
    editDraftQuotationLoading,
    editDraftAssessmentLoading,
    removeDraftQuotation,
    removeDraftAssessment,
    removeDraftQuotationLoading,
    removeDraftAssessmentLoading,
  };
};
