import { Box, Collapse, SelectChangeEvent, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  dateTimeISOFormat,
  temporaryRowId,
} from "../../../../../../../../../constants";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  AddItemStatusOptionInput,
  EditItemStatusOptionInput,
  ItemStatusOption,
  ItemStatusOptionStatus,
  ItemStatusOptionStyles,
  User,
} from "generated/graphql";
import { useColumns } from "./StatusCollectionsOptions.constants";
import { sortCollectionOptions } from "./StatusCollectionOptions.utils";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { ListBullets } from "phosphor-react";
import { NewAppPaths } from "helpers/paths/paths";
import { getUserName } from "helpers/miscelaneous";

export type StatusCollectionOptionsProps = {
  statusOptions: ItemStatusOption[];
  statusCollectionName?: string;
  statusCollectionId?: string;
  loading?: boolean;
  onCollectionOptionStatusChange: (
    row: ItemStatusOption,
    newStatus: ItemStatusOptionStatus
  ) => void;
  onCollectionOptionAdd: (statusOptionData: AddItemStatusOptionInput) => void;
  onCollectionOptionUpdate: (
    statusOptionData: EditItemStatusOptionInput
  ) => void;
};

export const StatusCollectionOptions: React.FC<
  StatusCollectionOptionsProps
> = ({
  statusOptions,
  statusCollectionId,
  statusCollectionName,
  loading,
  onCollectionOptionAdd,
  onCollectionOptionStatusChange,
  onCollectionOptionUpdate,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { productId } = useParams();
  const { authenticatedUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const statusesStatusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ItemStatusOptionStatus>[];

  const [rows, setRows] = useState<ItemStatusOption[]>(
    sortCollectionOptions(statusOptions)
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(statusOptions)
  );
  const [showOptions, setShowOptions] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ItemStatusOptionStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      {
        header: t("AdminConsole.Products.labels.displayOrder"),
        key: "displayOrder",
        width: 20,
      },
      {
        header: t("AdminConsole.Products.labels.statusOption"),
        key: "description",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
      {
        header: t("AdminConsole.Products.labels.equatesToRemoved"),
        key: "equatesToRemoved",
        width: 20,
      },
      {
        header: t("AdminConsole.Products.labels.style"),
        key: "style",
        width: 20,
      },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
      { header: t("common.labels.createdBy"), key: "creator", width: 20 },
    ];

    const rowsToExport = rows
      .filter(
        (statusOption) => (selectionModel || []).indexOf(statusOption.id) >= 0
      )
      .map((statusOption) => ({
        ...statusOption,
        displayOrder: parseInt(`${statusOption.displayOrder}`),
        equatesToRemoved: !!statusOption.equatesToRemoved,
        dateCreated: statusOption.dateCreated
          ? new Date(statusOption.dateCreated)
          : "",
        creator: getUserName(statusOption.creator),
      }));

    exportToExcel(
      `${statusCollectionName}-${t(
        "AdminConsole.Products.labels.collectionStatusOptions"
      )}`,
      columns,
      rowsToExport
    );
  };

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (rowData: GridRowParams<ItemStatusOption>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        navigate(
          NewAppPaths.authorized.AdminConsole.children.System.children.Products.children.ProductDetails.children.StatusCollectionDetails.children.CollectionOptionDetails.pathConstructor(
            productId!,
            statusCollectionId!,
            rowData.row.id
          )
        );
      }
    },
    [navigate, productId, statusCollectionId]
  );

  const handleRowChangesCommited = useCallback(
    (
      newRow: GridRowModel<ItemStatusOption>,
      oldRow: GridRowModel<ItemStatusOption>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (newRow.description && newRow.style) {
          onCollectionOptionAdd({
            description: newRow.description,
            displayOrder: newRow.displayOrder,
            equatesToRemoved: !!newRow.equatesToRemoved,
            style: newRow.style,
            statusCollectionId: statusCollectionId!,
          });
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "description",
            },
          }));
        }
      } else if (newRow.displayOrder !== oldRow.displayOrder) {
        onCollectionOptionUpdate({
          id: newRow.id,
          displayOrder: newRow.displayOrder,
          style: newRow.style,
        });
      }

      return newRow;
    },
    [onCollectionOptionAdd, onCollectionOptionUpdate, statusCollectionId]
  );

  const handleCollectionOptionStatusChange = useCallback(
    (row: ItemStatusOption, newStatus: ItemStatusOptionStatus) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                status: newStatus,
              };
            }

            return row;
          })
        );
      } else {
        onCollectionOptionStatusChange(row, newStatus);
      }
    },
    [onCollectionOptionStatusChange]
  );

  const handleEquatesToRemovedChange = useCallback((rowId: GridRowId) => {
    if (rowId === temporaryRowId) {
      setRows((curRows) =>
        curRows.map((row) => {
          if (row.id === temporaryRowId) {
            return {
              ...row,
              equatesToRemoved: !row.equatesToRemoved,
            };
          }

          return row;
        })
      );
    }
  }, []);

  const handleOptionStyleChange = useCallback(
    (row: ItemStatusOption, event: SelectChangeEvent<unknown>) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                style: event.target.value as ItemStatusOptionStyles,
              };
            }

            return row;
          })
        );
      }
    },
    []
  );

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        description: "",
        id: temporaryRowId,
        status: ItemStatusOptionStatus.Active,
        equatesToRemoved: false,
        statusCollectionId: statusCollectionId!,
        style: "" as ItemStatusOptionStyles,
        optionReasons: { items: [] },
        isInternal: false,
        displayOrder: rows.length + 1 || 1,
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "description",
        },
      }));
    });
  };

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onCollectionOptionStatusChange(
          statusOptions.find((statusOption) => statusOption.id === rowId)!,
          ItemStatusOptionStatus.Removed
        );
      }
    },
    [onCollectionOptionStatusChange, statusOptions]
  );

  const handleRowOrderChange: GridEventListener<"rowOrderChange"> = (
    params
  ) => {
    const collectionOption = params.row;
    const newIndex = params.targetIndex + 1;

    if (newIndex !== collectionOption.displayOrder) {
      onCollectionOptionUpdate({
        id: collectionOption.id,
        displayOrder: newIndex,
        style: collectionOption.style,
      });
    }
  };

  const columns = useColumns(
    statusesStatusOptions,
    rowModesModel,
    handleCollectionOptionStatusChange,
    handleEquatesToRemovedChange,
    handleOptionStyleChange,
    handleRowSaveClick,
    handleDeleteRow
  );

  useEffect(() => {
    setRows(sortCollectionOptions(statusOptions));
    setRowModesModel(computeGridRowModes(statusOptions));
  }, [statusOptions]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Products.labels.collectionStatusOptions")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <ListBullets
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowOptions((state) => !state)}
        collapsed={!showOptions}
      />
      <Collapse in={showOptions}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: ItemStatusOption) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            onRowClick={handleRowClick}
            processRowUpdate={handleRowChangesCommited}
            onRowOrderChange={handleRowOrderChange}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, ItemStatusOption, any>
            ) => {
              return params.row.status === ItemStatusOptionStatus.Removed
                ? "greyed-out"
                : "";
            }}
            rowReordering
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
