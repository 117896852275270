import { Box, useTheme, Divider } from "@mui/material";
import { dateTimeISOFormat } from "../../../../../constants";
import { useInvolvedUsers } from "containers/Projects/hooks/useInvolvedUsers";
import { ProductType, User } from "generated/graphql";
import { useHasAccess } from "hooks/useHasAccess";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { SchemaInterpretorContext } from "../SchemaInterpretor.context";
import { MetadataBox } from "./Metadata.styled";
import { MetadataHeading } from "../../../../../components/SchemaInterpretor/MetadataHeading";
import { MetadataText } from "../../../../../components/SchemaInterpretor/MetadataText";
import { OwnerSelect } from "./OwnerSelect/OwnerSelect";
import { MetadataDateSentHeading } from "../../../../../components/MetadataDateSentHeading";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { MetadataSenderHeading } from "./MetadataSenderHeading";
import { useChangeRiskOwner } from "hooks/useChangeRiskOwner";
import { useChangeEarlyWarningOwner } from "hooks/useChangeEarlyWarningOwner";
import { useChangeInstructionOwner } from "hooks/useChangeInstructionOwner";
import { useChangeEventOwner } from "hooks/useChangeEventOwner";
import { getUserName } from "helpers/miscelaneous";
import { DateWithTimeTooltip } from "../../DateWithTimeTooltip";

export const MetadataFooter: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser } = useContext(GlobalContext);
  const { productInstanceId } = useParams();
  const {
    productItemType,
    onOwnerChange,
    metadata,
    authAudit,
    contractTimezone,
  } = useContext(SchemaInterpretorContext);
  const {
    dateCreated,
    offline,
    creator,
    dateModified,
    owner,
    dateSent,
    productItemId,
  } = metadata!;

  const canChangeOwner =
    useHasAccess(undefined, [PermissionEnum.ChangeOwner], productInstanceId!) ||
    authenticatedUser?.id === owner.id;

  const { candidates: ownerCandidates, loading: ownersLoading } =
    useInvolvedUsers(productInstanceId!, true);

  const { changeRiskOwner, loading: changeRiskOwnerLoading } =
    useChangeRiskOwner(onOwnerChange);
  const { changeEarlyWarningOwner, loading: changeEarlyWarningOwnerLoading } =
    useChangeEarlyWarningOwner(onOwnerChange);
  const { changeInstructionOwner, loading: changeInstructionOwnerLoading } =
    useChangeInstructionOwner(onOwnerChange);
  const { changeEventOwner, loading: changeEventOwnerLoading } =
    useChangeEventOwner(onOwnerChange);

  const handleOwnerChange = async (newOwner: User) => {
    switch (productItemType) {
      case ProductType.RisksRegister:
        await changeRiskOwner({
          variables: { id: productItemId, ownerId: newOwner.id },
        });
        break;
      case ProductType.EarlyWarnings:
        await changeEarlyWarningOwner({
          variables: { id: productItemId, ownerId: newOwner.id },
        });
        break;
      case ProductType.Events:
        await changeEventOwner({
          variables: { id: productItemId, ownerId: newOwner.id },
        });
        break;
      case ProductType.Instructions:
        await changeInstructionOwner({
          variables: { id: productItemId, ownerId: newOwner.id },
        });
        break;
    }
  };

  const dateCreatedText = moment(dateCreated).format(dateTimeISOFormat);
  const creatorText =
    typeof creator === "string" ? creator : getUserName(creator);

  const ownerText = getUserName(owner);
  const loading =
    ownersLoading ||
    changeRiskOwnerLoading ||
    changeEarlyWarningOwnerLoading ||
    changeEventOwnerLoading ||
    changeInstructionOwnerLoading;

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box display="flex" flex={1} height="56px">
          <MetadataBox>
            <>
              {offline && dateSent ? (
                <>
                  <MetadataDateSentHeading
                    tooltipText={t(
                      productItemType === ProductType.EarlyWarnings
                        ? "Projects.EarlyWarnings.earlyWarningRecordedOn"
                        : "Projects.Instructions.instructionRecordedOn",
                      {
                        dateText: dateCreatedText,
                      }
                    )}
                  />
                  <DateWithTimeTooltip
                    datetime={dateSent}
                    timezone={contractTimezone}
                    variant="p1"
                    color={theme.palette.grey[600]}
                    mt={0.5}
                  />
                </>
              ) : (
                <>
                  <MetadataHeading>
                    {t(
                      `common.labels.${
                        [
                          ProductType.RisksRegister,
                          ProductType.Events,
                        ].includes(productItemType)
                          ? "dateCreated"
                          : "dateSent"
                      }`
                    )}
                  </MetadataHeading>
                  <DateWithTimeTooltip
                    datetime={dateCreatedText}
                    timezone={contractTimezone}
                    variant="p1"
                    color={theme.palette.grey[600]}
                    mt={0.5}
                  />
                </>
              )}
            </>
          </MetadataBox>
          <MetadataBox>
            <MetadataSenderHeading
              productItemType={productItemType}
              authAudit={authAudit}
            />
            <MetadataText>{creatorText}</MetadataText>
          </MetadataBox>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginLeft: theme.spacing(3), marginRight: theme.spacing(3) }}
        />
        <Box display="flex" flex={1} height="56px" overflow="hidden">
          <MetadataBox>
            <MetadataHeading>{t("common.labels.dateModified")}</MetadataHeading>
            <DateWithTimeTooltip
              datetime={dateModified}
              timezone={contractTimezone}
              variant="p1"
              color={theme.palette.grey[600]}
              mt={0.5}
            />
          </MetadataBox>
          <MetadataBox overflow="hidden">
            <MetadataHeading>{t("common.labels.owner")}</MetadataHeading>
            {canChangeOwner ? (
              <OwnerSelect
                owner={owner}
                ownerCandidates={(ownerCandidates as User[]) || []}
                onSelectionChange={handleOwnerChange}
                loading={loading}
              />
            ) : (
              <MetadataText>{ownerText}</MetadataText>
            )}
          </MetadataBox>
        </Box>
      </Box>
    </>
  );
};
