import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { Comments } from "containers/Projects/components/Comments/Comments";
import { EditRiskEventHeader } from "containers/Projects/components/EditRiskEventHeader/EditRiskEventHeader";
import { ExplorerContext } from "containers/Projects/components/Explorer/Explorer.context";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  EditEventItemMutation,
  EditEventItemMutationVariables,
  ItemDataInput,
  ProductSchema,
  ProductType,
} from "generated/graphql";
import { editEventItemMutation } from "graphql/mutations/editEventItem";
import { NewAppPaths } from "helpers/paths/paths";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useHasAccess } from "hooks/useHasAccess";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useCallback, useEffect, useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { noop } from "helpers/miscelaneous";
import { useEventItem } from "hooks/useEventItem";

export const EditEvent = () => {
  const { productInstanceId, eventItemId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onNavigateBack = useNavigateBack();
  const { authenticatedUser } = useContext(GlobalContext);
  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    setLoading: setExplorerDataLoading,
  } = useContext(ExplorerContext);

  const [schemaValues, setSchemaValues] = useState<ItemDataInput | null>({
    sections: [],
  });
  const [schemaValid, setSchemaValid] = useState<boolean>();

  const {
    eventItemData,
    refetchEventData,
    loading: getEventItemDataLoading,
    error,
  } = useEventItem(eventItemId!);

  const isCurrentUserOwner = useMemo(
    () => authenticatedUser?.id === eventItemData?.ownerId,
    [authenticatedUser, eventItemData]
  );
  const hasAccess =
    useHasAccess(undefined, [PermissionEnum.Edit], productInstanceId!) ||
    isCurrentUserOwner;
  const canManageAttachments =
    useHasAccess(
      undefined,
      [PermissionEnum.ManageAttachments],
      productInstanceId!
    ) || isCurrentUserOwner;

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    await handleSaveEvent(attachmentsUpdated, false);
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    ((eventItemData?.attachments as Attachment[]) ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    ) || [],
    handleAttachmentsUpdated
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const [doEditEvent, { loading: editEventLoading }] = useGraphMutation<
    EditEventItemMutation,
    EditEventItemMutationVariables
  >(
    editEventItemMutation,
    {
      update: () => {
        refetchEventData();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Events.event"),
    })
  );

  const handleSaveEvent = useCallback(
    async (
      updatedAttachments?: AttachmentInput[],
      navigateBack: boolean = true
    ) => {
      if (schemaValid) {
        await doEditEvent({
          variables: {
            input: {
              id: eventItemId!,
              data: schemaValues!,
              attachments:
                updatedAttachments ??
                attachmentsToAttachmentInputs(
                  (eventItemData?.attachments as Attachment[]) ?? []
                ),
            },
          },
        });

        if (navigateBack) {
          onNavigateBack();
        }
      }
    },
    [
      doEditEvent,
      eventItemId,
      schemaValues,
      schemaValid,
      onNavigateBack,
      eventItemData,
    ]
  );

  useEffect(() => {
    if (eventItemData?.data) {
      setSchemaValues(eventItemData.data);
    }
  }, [eventItemData]);

  useEffect(() => {
    if (eventItemData) {
      changeExplorerEntities({
        projectId: eventItemData.productInstance.contract.project.id,
        contractId: eventItemData.productInstance.contract.id,
        productId: eventItemData.productInstance.product.id,
        productInstanceId: eventItemData.productInstanceId,
      });
    }
  }, [changeExplorerEntities, eventItemData]);

  useEffect(() => {
    setExplorerDataLoading(true);
  }, [setExplorerDataLoading]);

  useEffect(() => {
    if (eventItemData) {
      setExplorerDataLoading(false);
    }
  }, [setExplorerDataLoading, eventItemData]);

  useEffect(() => {
    if (error && !eventItemData) {
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound);
    }
  }, [error, eventItemData, navigate, clearExplorerData]);

  if (!hasAccess && !!eventItemData) {
    return <Navigate to={NewAppPaths.authorized.Projects.path} replace />;
  }

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        contractTimezone={eventItemData?.productInstance.contract.timeZone}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <EditRiskEventHeader
            onCancel={onNavigateBack}
            onSave={() => handleSaveEvent()}
            disabled={getEventItemDataLoading}
            loading={editEventLoading}
            productType={ProductType.Events}
            onDuplicate={noop}
            onMoveToProject={noop}
          />
          <Box mt={3}>
            {getEventItemDataLoading ||
            schemaValues === null ||
            !eventItemData ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode
                productItemType={ProductType.Events}
                schema={
                  eventItemData!.productInstance.productSchema as ProductSchema
                }
                schemaValues={schemaValues}
                onSchemaValuesChange={setSchemaValues}
                onSchemaValidityChange={setSchemaValid}
                contractCurrency={
                  eventItemData!.productInstance.contract.valueCurrency ?? ""
                }
                contractTimezone={
                  eventItemData!.productInstance.contract.timeZone ?? ""
                }
                productInstanceId={eventItemData!.productInstanceId}
                mainColumnExtraWidgets={
                  <>
                    <Grid item xs={12} key={"attachments"} position="relative">
                      <SectionContainer>
                        <Attachments
                          editMode={canManageAttachments === true}
                          attachments={allAttachments}
                          onAttachmentsAdd={addAttachments}
                          onAttachmentRemove={removeAttachment}
                          onAttachmentUpdate={updateAttachment}
                          onAttachmentClick={handleAttachmentClick}
                        />
                      </SectionContainer>
                    </Grid>
                    <Grid item xs={12} key={"comments"} position="relative">
                      <SectionContainer>
                        <Comments
                          productType={ProductType.Events}
                          productItemId={eventItemId!}
                          productInstanceId={productInstanceId!}
                        />
                      </SectionContainer>
                    </Grid>
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
