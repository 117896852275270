import { SelectChangeEvent } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
  GridSingleSelectColDef,
} from "@mui/x-data-grid-pro";
import { ProductUserRoleStatus, Project } from "generated/graphql";
import { LocalProductUserRole } from "./UserRoles";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { DataGridSingleSelect } from "components/DataGridSingleSelect";
import {
  getCommonActions,
  statusComparatorFunction,
  useCreatorColumnConfig,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { DataGridSelectOption } from "components/CustomDataGridSingleSelect";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { dateTimeISOFormat } from "../../../../../../../constants";

export type IdNameType = {
  id: string;
  name: string;
};

type getColumnsArgsType = {
  rowModesModel: GridRowModesModel;
  projects: Project[];
  statusOptions: StatusOption<ProductUserRoleStatus>[];
  onProjectChange: (row: LocalProductUserRole, newProject: IdNameType) => void;
  onContractChange: (
    row: LocalProductUserRole,
    newContract: IdNameType
  ) => void;
  onProductChange: (row: LocalProductUserRole, newProduct: IdNameType) => void;
  onProductInstanceChange: (
    row: LocalProductUserRole,
    newProductInstance: IdNameType
  ) => void;
  onRoleChange: (row: LocalProductUserRole, newRole: IdNameType) => void;
  onStatusChange: (
    row: LocalProductUserRole,
    newStatus: ProductUserRoleStatus
  ) => void;
  handleSaveRow: (rowId: GridRowId) => void;
  handleDeleteRow: (rowId: GridRowId) => void;
};

export const useColumns = ({
  rowModesModel,
  projects,
  statusOptions,
  onProjectChange,
  onContractChange,
  onProductChange,
  onProductInstanceChange,
  onRoleChange,
  onStatusChange,
  handleSaveRow,
  handleDeleteRow,
}: getColumnsArgsType): GridColDef<LocalProductUserRole>[] => {
  const { t } = useTranslation();
  const dateCreatedCol = useDateCreatedColumnConfig(dateTimeISOFormat);
  const creatorCol = useCreatorColumnConfig<LocalProductUserRole>();
  const projectOptions = projects
    .map((project) => ({
      value: project.id,
      label: project.friendlyName,
    }))
    .sort((p1, p2) => p1.label.localeCompare(p2.label));

  return useMemo(
    () => [
      {
        field: "project",
        headerName: t("common.labels.project"),
        flex: 0.125,
        minWidth: 90,
        resizable: true,
        editable: true,
        type: "singleSelect",
        valueOptions: projectOptions,
        valueGetter: (_, row) => {
          return row.project?.id;
        },
        valueFormatter: (value) => {
          const projectId = value;
          const selectedOption = projectOptions.find(
            (valueOption: { value: string; label: string }) =>
              valueOption.value === projectId
          );

          return selectedOption?.label;
        },
        renderEditCell: (
          cellParams: GridRenderEditCellParams<any, LocalProductUserRole, any>
        ) => (
          <DataGridSingleSelect
            cellParams={cellParams}
            error={!cellParams.row.project}
            onValueChange={(event: SelectChangeEvent<any>) => {
              const newSelectedProject = projectOptions.find(
                (option) => option.value === event.target.value
              );
              onProjectChange(cellParams.row, {
                id: newSelectedProject!.value,
                name: newSelectedProject!.label,
              });
            }}
          />
        ),
      },
      {
        field: "contract",
        headerName: t("common.labels.contract"),
        flex: 0.125,
        minWidth: 90,
        resizable: true,
        editable: true,
        type: "singleSelect",
        valueOptions: (params) => {
          let crtRow: LocalProductUserRole;
          if ((params as any).api) {
            const rows = (params as any).api.getRowModels() as Map<
              string,
              LocalProductUserRole
            >;
            crtRow = rows.get(params.id as string)!;
          } else {
            crtRow = params.row!;
          }

          const selectedProject = projects.find(
            (proj) => proj.id === crtRow!.project?.id
          );

          return (
            selectedProject
              ? selectedProject.contracts.items.map((contract) => ({
                  value: contract.id,
                  label: contract.friendlyName,
                }))
              : []
          ).sort((c1, c2) => c1.label.localeCompare(c2.label));
        },
        valueGetter: (_, row) => {
          return row.contract?.id;
        },
        valueFormatter: (value, row, column, api) => {
          const contractId = value;

          const colDef = api.current.getColumn(
            column.field
          ) as GridSingleSelectColDef<LocalProductUserRole>;
          const valueOptions = (
            typeof colDef.valueOptions === "function"
              ? colDef.valueOptions({
                  field: column.field,
                  id: row.id,
                  row,
                })
              : colDef.valueOptions
          ) as DataGridSelectOption[] | undefined;
          const selectedOption = valueOptions?.find(
            (valueOption) => valueOption.value === contractId
          );

          return selectedOption?.label || "";
        },
        renderEditCell: (
          cellParams: GridRenderEditCellParams<any, LocalProductUserRole, any>
        ) => (
          <DataGridSingleSelect
            cellParams={cellParams}
            error={!cellParams.row.contract}
            onValueChange={(event: SelectChangeEvent<any>) => {
              const colDef =
                cellParams.colDef as GridSingleSelectColDef<LocalProductUserRole>;

              const options = (
                typeof colDef.valueOptions === "function"
                  ? colDef.valueOptions(cellParams)
                  : colDef.valueOptions
              ) as DataGridSelectOption[];

              const newSelectedContract = options.find(
                (option) => option.value === event.target.value
              );
              onContractChange(cellParams.row, {
                id: newSelectedContract!.value,
                name: newSelectedContract!.label as string,
              });
            }}
          />
        ),
      },
      {
        field: "product",
        headerName: t("common.labels.product"),
        flex: 0.125,
        minWidth: 90,
        resizable: true,
        editable: true,
        type: "singleSelect",
        valueOptions: (params) => {
          let crtRow: LocalProductUserRole;
          if ((params as any).api) {
            const rows = (params as any).api.getRowModels() as Map<
              string,
              LocalProductUserRole
            >;
            crtRow = rows.get(params.id as string)!;
          } else {
            crtRow = params.row!;
          }

          const selectedProject = projects.find(
            (proj) => proj.id === crtRow.project?.id
          );
          const selectedContract = selectedProject?.contracts.items.find(
            (contract) => contract.id === crtRow.contract?.id
          );
          const products = selectedContract
            ? selectedContract.productInstances.items.map(
                (productInstance) => productInstance.product
              )
            : [];
          const uniqProducts = products.filter(
            (product, index) => products.indexOf(product) === index
          );

          return uniqProducts
            .map((prod) => ({
              value: prod.id,
              label: prod.name,
            }))
            .sort((prod1, prod2) => prod1.label.localeCompare(prod2.label));
        },
        valueGetter: (_, row) => {
          return row.product?.id;
        },
        valueFormatter: (value, row, column, api) => {
          const productId = value;
          const colDef = api.current.getColumn(
            column.field
          ) as GridSingleSelectColDef<LocalProductUserRole>;
          const valueOptions = (
            typeof colDef.valueOptions === "function"
              ? colDef.valueOptions({
                  field: column.field,
                  id: row.id,
                  row,
                })
              : colDef.valueOptions
          ) as DataGridSelectOption[] | undefined;
          const selectedOption = valueOptions?.find(
            (valueOption) => valueOption.value === productId
          );

          return selectedOption?.label;
        },
        renderEditCell: (
          cellParams: GridRenderEditCellParams<any, LocalProductUserRole, any>
        ) => {
          const colDef =
            cellParams.colDef as GridSingleSelectColDef<LocalProductUserRole>;
          const options = (
            typeof colDef.valueOptions === "function"
              ? colDef.valueOptions(cellParams)
              : colDef.valueOptions
          ) as DataGridSelectOption[];

          return (
            <DataGridSingleSelect
              cellParams={cellParams}
              error={!cellParams.row.product}
              onValueChange={(event: SelectChangeEvent<any>) => {
                const newSelectedProduct = options.find(
                  (option) => option.value === event.target.value
                );

                onProductChange(cellParams.row, {
                  id: newSelectedProduct!.value,
                  name: newSelectedProduct!.label as string,
                });
              }}
            />
          );
        },
      },
      {
        field: "productInstance",
        headerName: t("common.labels.productInstance"),
        flex: 0.125,
        minWidth: 90,
        resizable: true,
        editable: true,
        type: "singleSelect",
        valueOptions: (params) => {
          let crtRow: LocalProductUserRole;
          if ((params as any).api) {
            const rows = (params as any).api.getRowModels() as Map<
              string,
              LocalProductUserRole
            >;
            crtRow = rows.get(params.id as string)!;
          } else {
            crtRow = params.row!;
          }

          const selectedProject = projects.find(
            (proj) => proj.id === crtRow.project?.id
          );
          const selectedContract = selectedProject?.contracts.items.find(
            (contract) => contract.id === crtRow.contract?.id
          );
          const products = selectedContract
            ? selectedContract.productInstances.items.map(
                (productInstance) => productInstance.product
              )
            : [];
          const uniqProducts = products.filter(
            (product, index) => products.indexOf(product) === index
          );

          const productInstances = selectedContract
            ? selectedContract.productInstances.items
            : [];
          const filteredProductInstances =
            uniqProducts.length > 1
              ? productInstances.filter(
                  (prodInstance) =>
                    prodInstance.product.id === crtRow.product?.id
                )
              : productInstances;

          return filteredProductInstances
            .map((prodInstance) => ({
              value: prodInstance.id,
              label: prodInstance.description,
            }))
            .sort((productInstance1, productInstance2) =>
              productInstance1.label.localeCompare(productInstance2.label)
            );
        },
        valueGetter: (_, row) => {
          return row.productInstance?.id;
        },
        valueFormatter: (value, row, column, api) => {
          const productInstanceId = value;
          const colDef = api.current.getColumn(
            column.field
          ) as GridSingleSelectColDef<LocalProductUserRole>;

          const valueOptions = (
            typeof colDef.valueOptions === "function"
              ? colDef.valueOptions({
                  id: row.id,
                  field: column.field,
                  row,
                })
              : colDef.valueOptions
          ) as DataGridSelectOption[] | undefined;

          const selectedOption = valueOptions?.find(
            (valueOption) => valueOption.value === productInstanceId
          );

          return selectedOption?.label;
        },
        renderEditCell: (
          cellParams: GridRenderEditCellParams<any, LocalProductUserRole, any>
        ) => (
          <DataGridSingleSelect
            cellParams={cellParams}
            error={!cellParams.row.productInstance}
            onValueChange={(event: SelectChangeEvent<any>) => {
              const colDef =
                cellParams.colDef as GridSingleSelectColDef<LocalProductUserRole>;
              const options = (
                typeof colDef.valueOptions === "function"
                  ? colDef.valueOptions(cellParams)
                  : colDef.valueOptions
              ) as DataGridSelectOption[];
              const newSelectedProductInstance = options.find(
                (option) => option.value === event.target.value
              );

              onProductInstanceChange(cellParams.row, {
                id: newSelectedProductInstance!.value,
                name: newSelectedProductInstance!.label as string,
              });
            }}
          />
        ),
      },
      {
        field: "productRole",
        headerName: t("common.labels.role"),
        minWidth: 90,
        resizable: true,
        editable: true,
        type: "singleSelect",
        valueOptions: (params) => {
          let crtRow: LocalProductUserRole;
          if ((params as any).api) {
            const rows = (params as any).api.getRowModels() as Map<
              string,
              LocalProductUserRole
            >;
            crtRow = rows.get(params.id as string)!;
          } else {
            crtRow = params.row!;
          }

          const selectedProject = projects.find(
            (proj) => proj.id === crtRow.project?.id
          );
          const selectedContract = selectedProject?.contracts.items.find(
            (contract) => contract.id === crtRow.contract?.id
          );
          const selectedProduct = selectedContract?.productInstances.items.find(
            (prodInstance) => prodInstance.id === crtRow.productInstance?.id
          )?.product;

          return selectedProduct
            ? selectedProduct.roles.items.map((role) => ({
                value: role.id,
                label: role.name,
              }))
            : [];
        },
        valueGetter: (_, row) => {
          return row.role?.id;
        },
        valueFormatter: (value, row, column, api) => {
          const productRoleId = value;
          const colDef = api.current.getColumn(
            column.field
          ) as GridSingleSelectColDef<LocalProductUserRole>;
          const valueOptions = (
            typeof colDef.valueOptions === "function"
              ? colDef.valueOptions({
                  id: row.id,
                  field: column.field,
                  row,
                })
              : colDef.valueOptions
          ) as DataGridSelectOption[] | undefined;

          const selectedOption = valueOptions?.find(
            (valueOption) => valueOption.value === productRoleId
          );

          return selectedOption?.label;
        },
        renderEditCell: (
          cellParams: GridRenderEditCellParams<any, LocalProductUserRole, any>
        ) => (
          <DataGridSingleSelect
            cellParams={cellParams}
            error={!cellParams.row.role}
            onValueChange={(event: SelectChangeEvent<any>) => {
              const colDef =
                cellParams.colDef as GridSingleSelectColDef<LocalProductUserRole>;
              const options =
                typeof colDef.valueOptions === "function"
                  ? (colDef.valueOptions(cellParams) as DataGridSelectOption[])
                  : [];
              const newSelectedRole = options.find(
                (option) => option.value === event.target.value
              );

              onRoleChange(cellParams.row, {
                id: newSelectedRole!.value,
                name: newSelectedRole!.label as string,
              });
            }}
          />
        ),
      },
      {
        field: "status",
        headerName: t("common.labels.status"),
        width: 110,
        resizable: true,
        sortComparator: statusComparatorFunction,
        renderCell: (
          params: GridRenderCellParams<any, LocalProductUserRole, any>
        ) => {
          return (
            <StatusTag
              status={params.row.status}
              options={statusOptions}
              onChange={(newStatus: StatusOption<ProductUserRoleStatus>) =>
                onStatusChange(params.row, newStatus.id)
              }
            />
          );
        },
      },
      {
        ...dateCreatedCol,
        width: 160,
      },
      creatorCol,
      {
        field: "actions",
        flex: 0.05,
        type: "actions",
        sortable: false,
        getActions: (cellParams) =>
          getCommonActions(
            cellParams,
            rowModesModel,
            handleSaveRow,
            handleDeleteRow
          ),
      },
    ],
    [
      dateCreatedCol,
      handleDeleteRow,
      handleSaveRow,
      onRoleChange,
      onProductChange,
      onContractChange,
      onProductInstanceChange,
      onProjectChange,
      onStatusChange,
      projects,
      projectOptions,
      rowModesModel,
      statusOptions,
      creatorCol,
      t,
    ]
  );
};
