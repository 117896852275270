import {
  Box,
  Grid,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  PriceTimeTag,
  PriceTimeTagStyle,
  TagStatusStyles,
} from "./PriceTimeTag";
import React, { useMemo } from "react";
import { ListItemsDivider } from "components/ListItemsDivider";
import {
  Attachment,
  AttachmentStatus,
  Contract,
  ContractKeyDateStatus,
  ContractSectionStatus,
  ProductType,
} from "generated/graphql";
import { RichTextArea } from "../../../../components/RichTextArea/RichTextArea";
import { PhotoAttachmentPreviewModal } from "../PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { useImagePreviewModal } from "../Attachments/hooks/useImagePreviewModal";
import { Attachments } from "../Attachments/Attachments";
import { AttachmentsDencity } from "../Attachments/Attachments.decl";
import moment from "moment";
import { useAttachments } from "../Attachments/hooks/useAttachments";
import { dateISOFormat } from "../../../../constants";
import { dailyDiaryTimeFormat } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";
import getSymbolFromCurrency from "currency-symbol-map";
import { formatNumber } from "helpers/miscelaneous";
import { TimeTag } from "./TimeTag";

type TimeChange = {
  days: number;
  description: string;
  number?: number | null;
};

type TimeChangeLite = {
  id: string;
  days: number;
};

type ContractPriceTimeChangeReadOnlyViewProps = {
  details?: string;
  detailsCustomRender?: React.ReactNode;
  time: number;
  price: number;
  contract: Contract;
  dateSent: string;
  isFIDIC17White?: boolean;
  keyDatesChanges?: TimeChangeLite[] | TimeChange[] | null;
  sectionalChanges?: TimeChangeLite[] | TimeChange[] | null;
  attachments?: Attachment[];
  productType:
    | ProductType.Claims
    | ProductType.CompEvents
    | ProductType.Variations;
};

const SectionTitle: React.FC<
  TypographyProps & { children: React.ReactNode }
> = ({ children, ...restProps }) => (
  <Typography
    variant="h4"
    fontWeight={600}
    fontSize="18px"
    color="grey.800"
    {...restProps}
  >
    {children}
  </Typography>
);

const TimeImpactField: React.FC<{
  timeImpact: TimeChange;
}> = ({ timeImpact }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="p2" color="grey.800">
        {timeImpact.description}
      </Typography>
      <TimeTag value={timeImpact.days} />
    </Box>
  );
};

const TimeImpactFields: React.FC<{
  timeImpactChanges: TimeChange[];
  title: string;
}> = ({ title, timeImpactChanges }) => (
  <>
    <SectionTitle mb={2}>{title}</SectionTitle>
    {timeImpactChanges.map((timeImpactChange, index) => (
      <React.Fragment key={timeImpactChange.number}>
        <TimeImpactField timeImpact={timeImpactChange} />
        {index < timeImpactChanges.length - 1 && (
          <ListItemsDivider sx={{ my: 1.5 }} />
        )}
      </React.Fragment>
    ))}
  </>
);

export const ContractPriceTimeChangeReadOnlyView: React.FC<
  ContractPriceTimeChangeReadOnlyViewProps
> = ({
  contract,
  attachments = [],
  details,
  detailsCustomRender,
  dateSent,
  price,
  time,
  keyDatesChanges,
  sectionalChanges,
  isFIDIC17White,
  productType,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { allAttachments, downloadAttachment } = useAttachments(
    attachments.filter((attach) => attach.status === AttachmentStatus.Active)
  );

  const keyDatesTimeImpacts = useMemo(() => {
    if (keyDatesChanges && keyDatesChanges.length) {
      if ((keyDatesChanges[0] as TimeChangeLite).id) {
        return contract.keyDates
          .filter((keyDate) => keyDate.status !== ContractKeyDateStatus.Removed)
          .sort((keyDate1, keyDate2) => keyDate1.number! - keyDate2.number!)
          .map((keyDate) => {
            const dateChangeDays = (keyDatesChanges as TimeChangeLite[]).find(
              (keyDateChange) => keyDateChange?.id === keyDate.id
            );

            return {
              days: dateChangeDays!.days,
              description: keyDate.conditionToBeMet,
              number: keyDate.number,
            };
          });
      }
      return keyDatesChanges as TimeChange[];
    } else {
      return [];
    }
  }, [contract, keyDatesChanges]);

  const sectionDatesTimeImpacts = useMemo(() => {
    if (sectionalChanges && sectionalChanges.length) {
      if ((sectionalChanges[0] as TimeChangeLite).id) {
        return contract.sections
          .filter((section) => section.status !== ContractSectionStatus.Removed)
          .sort((section1, section2) => section1.number! - section2.number!)
          .map((section) => {
            const dateChangeDays = (sectionalChanges as TimeChangeLite[]).find(
              (sectionDateChange) => sectionDateChange?.id === section.id
            );

            return {
              days: dateChangeDays!.days,
              description: section.description,
              number: section.number,
            };
          });
      }
      return sectionalChanges as TimeChange[];
    } else {
      return [];
    }
  }, [contract, sectionalChanges]);

  const additionalPaymentLabel = t(
    productType === ProductType.CompEvents
      ? "common.labels.price"
      : productType === ProductType.Claims
      ? "Projects.Claims.DetailedClaimModal.additionalPayment"
      : "Projects.Variations.VariationProposalModal.additionalPayment"
  );

  const timeCompletionDateLabel = t(
    productType === ProductType.CompEvents
      ? "AdminConsole.ContractSections.completionDate"
      : productType === ProductType.Claims
      ? "Projects.Claims.DetailedClaimModal.timeCompletionDate"
      : `Projects.Variations.VariationProposalModal.${
          isFIDIC17White
            ? "timeCompletionDateFIDIC17White"
            : "timeCompletionDate"
        }`
  );

  const sectionalCompletionDatesLabel = t(
    productType === ProductType.CompEvents
      ? "Projects.CompEvents.QuotationAssessmentModal.sectionalCompletionDates"
      : productType === ProductType.Claims
      ? "Projects.Claims.DetailedClaimModal.sectionalCompletionDates"
      : "Projects.Variations.VariationProposalModal.sectionalCompletionDates"
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  return (
    <>
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        contractTimezone={contract.timeZone}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ListItemsDivider sx={{ mt: 0, mb: 4 }} />
            {detailsCustomRender ?? (
              <RichTextArea
                content={details ?? ""}
                readOnly
                color={theme.palette.grey[600]}
              />
            )}
            <Box mt={3} mb={0.5}>
              <Typography variant="p2" fontWeight={600} color="grey.800">
                {t("common.labels.dateSent")}
              </Typography>
              <Typography variant="p1" color="grey.600" component="p">
                {moment(dateSent).format(
                  `${dateISOFormat} ${dailyDiaryTimeFormat}`
                )}
              </Typography>
            </Box>
            <ListItemsDivider sx={{ mb: 0, mt: 4 }} />
          </Grid>
          <Grid item xs={12}>
            <SectionTitle mb={2}>{additionalPaymentLabel}</SectionTitle>
            <PriceTimeTag
              type="price"
              label={
                price ? (
                  <Typography
                    variant="p1"
                    fontWeight={600}
                    color={
                      TagStatusStyles[
                        price !== 0 ? (price > 0 ? "Red" : "Green") : "Grey"
                      ]
                    }
                  >{`${getSymbolFromCurrency(
                    contract.valueCurrency ?? ""
                  )} ${formatNumber(Math.abs(price))}`}</Typography>
                ) : undefined
              }
              style={
                price > 0
                  ? PriceTimeTagStyle.Increase
                  : price < 0
                  ? PriceTimeTagStyle.Decrease
                  : PriceTimeTagStyle.Static
              }
              sx={{
                width: "100%",
                height: "40px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SectionTitle mb={2}>{timeCompletionDateLabel}</SectionTitle>
            <PriceTimeTag
              type="time"
              label={
                time ? (
                  <Typography
                    variant="p1"
                    fontWeight={600}
                    color={
                      TagStatusStyles[
                        time !== 0 ? (time > 0 ? "Red" : "Green") : "Grey"
                      ]
                    }
                  >
                    {t("common.labels.xDays", {
                      number: Math.abs(time),
                      count: Math.abs(time),
                    })}
                  </Typography>
                ) : undefined
              }
              style={
                time > 0
                  ? PriceTimeTagStyle.Increase
                  : time < 0
                  ? PriceTimeTagStyle.Decrease
                  : PriceTimeTagStyle.Static
              }
              sx={{ width: "100%", height: "40px" }}
            />
          </Grid>
          {keyDatesChanges && keyDatesChanges.length > 0 && (
            <Grid item xs={12}>
              <TimeImpactFields
                title={t(
                  "Projects.CompEvents.QuotationAssessmentModal.keyDates"
                )}
                timeImpactChanges={keyDatesTimeImpacts}
              />
            </Grid>
          )}
          {sectionalChanges && sectionalChanges.length > 0 && (
            <Grid item xs={12}>
              <TimeImpactFields
                title={sectionalCompletionDatesLabel}
                timeImpactChanges={sectionDatesTimeImpacts}
              />
            </Grid>
          )}
          {allAttachments && allAttachments.length > 0 && (
            <Grid item xs={12}>
              <Attachments
                editMode={false}
                showTabs={false}
                dencity={AttachmentsDencity.Compact}
                attachments={allAttachments}
                onAttachmentClick={handleAttachmentClick}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
