import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationErrorAgreementDisagreementPreviewMutation,
  GenerateRecordVariationErrorAgreementDisagreementPreviewMutationVariables,
  RecordVariationErrorAgreementDisagreementPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateRecordVariationErrorAgreementDisagreementPreviewMutation } from "./DisagreeWithNoticeOfErrorInAgreement.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useRecordDisagreementRegardingNoticeOfErrorInAgreementModal = (
  remarks: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [preview, setPreview] =
    useState<RecordVariationErrorAgreementDisagreementPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationErrorAgreementDisagreementPreviewMutation,
    GenerateRecordVariationErrorAgreementDisagreementPreviewMutationVariables
  >(generateRecordVariationErrorAgreementDisagreementPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
          },
        },
      });

      if (data) {
        setPreview(
          data.generateRecordVariationErrorAgreementDisagreementPreview
        );
      }
    }, [generatePreview, variation, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview: preview,
    contract,
  };
};
