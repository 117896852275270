import {
  RecordVariationFurtherParticularsSubmissionMutation,
  RecordVariationFurtherParticularsSubmissionMutationVariables,
  SendVariationFurtherParticularsSubmissionMutation,
  SendVariationFurtherParticularsSubmissionMutationVariables,
  VariationFurtherParticularsSubmissionPromptQuery,
  VariationFurtherParticularsSubmissionPromptQueryVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OnMutationDone } from "decl";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  recordVariationFurtherParticularsSubmissionMutation,
  sendVariationFurtherParticularsSubmissionMutation,
  variationFurtherParticularsSubmissionPromptQuery,
} from "./ProvideFurtherParticularsAction.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useProvideFurtherParticularsAction = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationHistoryItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationProposalExplorerItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationAction",
    });
    cache.gc();
    onChange?.();
  };

  const { data: promptData, loading } = useGraphQuery<
    VariationFurtherParticularsSubmissionPromptQuery,
    VariationFurtherParticularsSubmissionPromptQueryVariables
  >(variationFurtherParticularsSubmissionPromptQuery, {
    variables: {
      input: {
        variationId: variation!.id,
      },
    },
    skip: !variation,
  });

  const [
    sendFurtherParticularsSubmission,
    { loading: sendFurtherParticularsSubmissionLoading },
  ] = useGraphMutation<
    SendVariationFurtherParticularsSubmissionMutation,
    SendVariationFurtherParticularsSubmissionMutationVariables
  >(
    sendVariationFurtherParticularsSubmissionMutation,
    {
      update: handleOnUpdate,
    },
    t("Projects.Variations.successMessages.furtherParticularsSubmissionSent")
  );

  const [
    recordFurtherParticularsSubmission,
    { loading: recordFurtherParticularsSubmissionLoading },
  ] = useGraphMutation<
    RecordVariationFurtherParticularsSubmissionMutation,
    RecordVariationFurtherParticularsSubmissionMutationVariables
  >(
    recordVariationFurtherParticularsSubmissionMutation,
    {
      update: handleOnUpdate,
    },
    t("Projects.Variations.successMessages.furtherParticularsSubmissionRecord")
  );

  return {
    details: promptData?.variationFurtherParticularsSubmissionPrompt.details,
    loading,
    actionLoading:
      sendFurtherParticularsSubmissionLoading ||
      recordFurtherParticularsSubmissionLoading,
    sendFurtherParticularsSubmission,
    recordFurtherParticularsSubmission,
  };
};
