import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import { exportToExcel } from "helpers/exportToExcel";
import { useState, useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import {
  AddEffectPresetInput,
  ContractType,
  EditEffectPresetInput,
  EffectPreset,
  EffectPresetStatus,
  User,
} from "generated/graphql";
import { useColumns } from "./EffectPresets.constants";
import {
  dateTimeISOFormat,
  temporaryRowId,
} from "../../../../../../../../constants";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import moment from "moment";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { useNavigate } from "react-router-dom";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { ListBullets } from "phosphor-react";
import { NewAppPaths } from "helpers/paths/paths";
import { getUserName } from "helpers/miscelaneous";

export type EffectPresetsProps = {
  effectPresets: EffectPreset[];
  contractTypes: ContractType[];
  loading?: boolean;
  onStatusChange: (
    effectPreset: EffectPreset,
    newStatus: EffectPresetStatus
  ) => void;
  onAdd: (input: AddEffectPresetInput) => any;
  onUpdate: (input: EditEffectPresetInput) => void;
};

export const EffectPresets: React.FC<EffectPresetsProps> = ({
  effectPresets,
  contractTypes,
  loading,
  onStatusChange,
  onAdd,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser } = useContext(GlobalContext);
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<EffectPresetStatus>[];
  const navigate = useNavigate();

  const [rows, setRows] = useState<EffectPreset[]>(effectPresets);
  const [showEffectPresets, setShowEffectPresets] = useState(true);
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(rows)
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [EffectPresetStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name", width: 20 },
      { header: t("common.labels.status"), key: "status", width: 20 },
      {
        header: "Contract type default",
        key: "isDefault",
        width: 20,
      },
      {
        header: "Contract type",
        key: "contractTypeId",
        width: 20,
      },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
      { header: t("common.labels.createdBy"), key: "creator", width: 20 },
    ];

    const rowsToExport = effectPresets
      .filter(
        (effectPreset) => (selectionModel || []).indexOf(effectPreset.id) >= 0
      )
      .map((effectPreset) => {
        const selectedContractType = contractTypes.find(
          (ct) => ct.id === effectPreset.contractTypeId
        );

        return {
          ...effectPreset,
          dateCreated: effectPreset.dateCreated
            ? new Date(effectPreset.dateCreated)
            : "",
          contractTypeId: `${selectedContractType?.description} ${
            selectedContractType?.version ?? ""
          } ${selectedContractType?.subType ?? ""}`,
          creator: getUserName(effectPreset.creator),
        };
      });

    exportToExcel(
      t("AdminConsole.Effect.labels.effectPresets"),
      columns,
      rowsToExport
    );
  };

  const handleStatusChange = useCallback(
    (row: EffectPreset, newStatus: EffectPresetStatus) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                status: newStatus,
              };
            }

            return row;
          })
        );
      } else {
        onStatusChange(row, newStatus);
      }
    },
    [onStatusChange]
  );

  const handleRowChangesCommited = useCallback(
    async (
      newRow: GridRowModel<EffectPreset>,
      oldRow: GridRowModel<EffectPreset>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (newRow.contractTypeId) {
          const success = await onAdd({
            contractTypeId: newRow.contractTypeId,
            isDefault: newRow.isDefault,
            name: newRow.name,
          });

          if (!success) {
            // remove temporary row
            setRows((curRows) =>
              curRows.filter((curRow) => curRow.id !== temporaryRowId)
            );
          }
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "contractTypeId",
            },
          }));
        }
      } else if (
        newRow.contractTypeId !== oldRow.contractTypeId ||
        newRow.name !== oldRow.name
      ) {
        onUpdate({
          id: String(newRow.id),
          contractTypeId: newRow.contractTypeId,
          isDefault: newRow.isDefault,
          name: newRow.name,
        });
      }

      return newRow;
    },
    [onAdd, onUpdate]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onStatusChange(
          rows.find((role) => role.id === rowId)!,
          EffectPresetStatus.Removed
        );
      }
    },
    [onStatusChange, rows]
  );

  const handleIsDefaultChange = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                isDefault: !row.isDefault,
              };
            }

            return row;
          })
        );
      } else {
        const updatedEffectPreset = effectPresets.find(
          (effectPreset) => effectPreset.id === rowId
        )!;

        onUpdate({
          id: String(rowId),
          contractTypeId: updatedEffectPreset.contractTypeId,
          isDefault: !updatedEffectPreset.isDefault,
          name: updatedEffectPreset.name,
        });
      }
    },
    [onUpdate, effectPresets]
  );

  const handleContractTypeChange = useCallback(
    (row: EffectPreset, newContractTypeId: string) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                contractTypeId: newContractTypeId,
              };
            }

            return row;
          })
        );
      } else {
        onUpdate({
          id: String(row.id),
          contractTypeId: newContractTypeId,
          isDefault: row.isDefault,
          name: row.name,
        });
      }
    },
    [onUpdate]
  );

  // TODO: export these functions in generic hook
  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (clickedRowData: GridRowParams<EffectPreset>) => {
      const selection = window.getSelection()?.toString();

      if (!selection) {
        navigate(
          NewAppPaths.authorized.AdminConsole.children.System.children.LookupsEffect.children.EffectPreset.pathConstructor(
            clickedRowData.row.id
          )
        );
      }
    },
    [navigate]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        contractTypeId: "",
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        id: temporaryRowId,
        isDefault: false,
        isInternal: false,
        name: "",
        options: { items: [] },
        status: EffectPresetStatus.Active,
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "name",
        },
      }));
    });
  };

  const columns = useColumns(
    statusOptions,
    contractTypes,
    handleStatusChange,
    rowModesModel,
    handleRowSaveClick,
    handleDeleteRow,
    handleIsDefaultChange,
    handleContractTypeChange
  );

  useEffect(() => {
    setRows(effectPresets);
    setRowModesModel(computeGridRowModes(effectPresets));
  }, [effectPresets]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Effect.labels.effectPresets")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <ListBullets
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowEffectPresets((state) => !state)}
        collapsed={!showEffectPresets}
      />
      <Collapse in={showEffectPresets}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows || []}
            columns={columns}
            getRowId={(rowData: EffectPreset) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            rowModesModel={rowModesModel}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            getCellClassName={(
              params: GridCellParams<any, EffectPreset, any>
            ) => {
              return params.row.status === EffectPresetStatus.Removed
                ? "greyed-out"
                : "";
            }}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            onRowClick={handleRowClick}
            // experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
