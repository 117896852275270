import { useCallback, useContext, useEffect, useState } from "react";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  GenerateSendVariationFurtherParticularsSubmissionPreviewMutation,
  GenerateSendVariationFurtherParticularsSubmissionPreviewMutationVariables,
  SendVariationFurtherParticularsSubmissionPreview,
} from "generated/graphql";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateSendVariationFurtherParticularsSubmissionPreviewMutation } from "./ProvideFurtherParticularsAction.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useSendFurtherParticularsSubmissionModal = (details: string) => {
  const [preview, setPreview] =
    useState<SendVariationFurtherParticularsSubmissionPreview>();

  const { variation, contract } = useContext(VariationWidgetContext);

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationFurtherParticularsSubmissionPreviewMutation,
    GenerateSendVariationFurtherParticularsSubmissionPreviewMutationVariables
  >(generateSendVariationFurtherParticularsSubmissionPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            details,
          },
        },
      });

      if (data) {
        setPreview(
          data.generateSendVariationFurtherParticularsSubmissionPreview
        );
      }
    }, [generatePreview, variation, details]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview: preview,
    contract,
  };
};
