import {
  RecordVariationErrorAgreementNoticeMutation,
  RecordVariationErrorAgreementNoticeMutationVariables,
  SendVariationErrorAgreementNoticeMutation,
  SendVariationErrorAgreementNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  recordVariationErrorAgreementNoticeMutation,
  sendVariationErrorAgreementNoticeMutation,
} from "./NotifyErrorFoundInAgreement.query";
import { OnMutationDone } from "decl";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useNotifyErrorFoundInAgreement = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationHistoryItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationProposalExplorerItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationAction",
    });
    cache.gc();
    onChange?.();
  };

  const [
    sendErrorAgreementNotice,
    { loading: sendErrorAgreementNoticeLoading },
  ] = useGraphMutation<
    SendVariationErrorAgreementNoticeMutation,
    SendVariationErrorAgreementNoticeMutationVariables
  >(
    sendVariationErrorAgreementNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Variations.successMessages.errorInAgreement"),
    })
  );

  const [
    recordErrorAgreementNotice,
    { loading: recordErrorAgreementNoticeLoading },
  ] = useGraphMutation<
    RecordVariationErrorAgreementNoticeMutation,
    RecordVariationErrorAgreementNoticeMutationVariables
  >(
    recordVariationErrorAgreementNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Variations.successMessages.errorInAgreement"),
    })
  );

  return {
    actionLoading:
      sendErrorAgreementNoticeLoading || recordErrorAgreementNoticeLoading,
    sendErrorAgreementNotice,
    recordErrorAgreementNotice,
  };
};
