import {
  GenerateSendVariationObjectionProposedVariationPreviewMutation,
  GenerateSendVariationObjectionProposedVariationPreviewMutationVariables,
  SendVariationObjectionProposedVariationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateSendVariationObjectionProposedVariationPreviewMutation } from "./ObjectionToProposedVariationAction.query";

export const useSendObjectionToProposedVariationModal = (reasons: string) => {
  const { variation, contract } = useContext(VariationWidgetContext);
  const [variationPreview, setVariationPreview] =
    useState<SendVariationObjectionProposedVariationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationObjectionProposedVariationPreviewMutation,
    GenerateSendVariationObjectionProposedVariationPreviewMutationVariables
  >(generateSendVariationObjectionProposedVariationPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            reasons,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateSendVariationObjectionProposedVariationPreview
        );
      }
    }, [generatePreview, variation, reasons]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview,
    contract,
  };
};
