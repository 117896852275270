import {
  GridColDef,
  GridEditInputCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { temporaryRowId } from "../../../../../../../../constants";
import { LookupOption, LookupOptionStatus } from "generated/graphql";
import { Typography } from "@mui/material";
import {
  getCommonActions,
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  statusOptions: StatusOption<LookupOptionStatus>[],
  onStatusChange: (row: LookupOption, newStatus: LookupOptionStatus) => void,
  rowModesModel: GridRowModesModel,
  handleSaveRow: (rowId: GridRowId) => void,
  handleDeleteRow: (rowId: GridRowId) => void
): GridColDef<LookupOption>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();

  const columns = useMemo(
    () =>
      [
        {
          field: "value",
          headerName: t("common.labels.value"),
          flex: 0.45,
          minWidth: 190,
          resizable: true,
          editable: true,
          renderEditCell: (
            params: GridRenderEditCellParams<any, LookupOption>
          ) => {
            return params.row.id !== temporaryRowId ? (
              <Typography variant="body2">{params.row.value}</Typography>
            ) : (
              <GridEditInputCell {...params} error={!params.row.value} />
            );
          },
        },
        {
          field: "displayOrder",
          headerName: t("AdminConsole.Products.labels.displayOrder"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
          editable: false,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, LookupOption, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                disabled={params.row.id === temporaryRowId}
                disablePortal={false}
                onChange={(newStatus: StatusOption<LookupOptionStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          width: 130,
          resizable: true,
          valueGetter: (_, row) => {
            return getUserName(row.creator);
          },
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          getActions: (cellParams) =>
            getCommonActions(
              cellParams,
              rowModesModel,
              handleSaveRow,
              handleDeleteRow
            ),
        },
      ] as GridColDef<LookupOption>[],
    [
      t,
      dateCreatedColConfig,
      statusOptions,
      onStatusChange,
      rowModesModel,
      handleSaveRow,
      handleDeleteRow,
    ]
  );
  return columns;
};
