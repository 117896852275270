import {
  RecordVariationErrorDeterminationNoticeMutation,
  RecordVariationErrorDeterminationNoticeMutationVariables,
  SendVariationErrorDeterminationNoticeMutation,
  SendVariationErrorDeterminationNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OnMutationDone } from "decl";
import {
  recordVariationErrorDeterminationNoticeMutation,
  sendVariationErrorDeterminationNoticeMutation,
} from "./NotifyErrorFoundInDeterminationAction.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useNotifyErrorFoundInDetermination = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationHistoryItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationProposalExplorerItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationAction",
    });
    cache.gc();
    onChange?.();
  };

  const [
    sendErrorDeterminationNotice,
    { loading: sendErrorDeterminationNoticeLoading },
  ] = useGraphMutation<
    SendVariationErrorDeterminationNoticeMutation,
    SendVariationErrorDeterminationNoticeMutationVariables
  >(
    sendVariationErrorDeterminationNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Variations.successMessages.errorInDetermination"),
    })
  );

  const [
    recordErrorDeterminationNotice,
    { loading: recordErrorDeterminationNoticeLoading },
  ] = useGraphMutation<
    RecordVariationErrorDeterminationNoticeMutation,
    RecordVariationErrorDeterminationNoticeMutationVariables
  >(
    recordVariationErrorDeterminationNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Variations.successMessages.errorInDetermination"),
    })
  );

  return {
    actionLoading:
      sendErrorDeterminationNoticeLoading ||
      recordErrorDeterminationNoticeLoading,
    sendErrorDeterminationNotice,
    recordErrorDeterminationNotice,
  };
};
