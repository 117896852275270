import { useCallback, useContext, useEffect, useState } from "react";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  GenerateSendVariationFurtherParticularsRequestPreviewMutation,
  GenerateSendVariationFurtherParticularsRequestPreviewMutationVariables,
  SendVariationFurtherParticularsRequestPreview,
} from "generated/graphql";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateSendVariationFurtherParticularsRequestPreviewMutation } from "./RequestFurtherParticularsAction.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useSendFurtherParticularsRequestModal = (reasons: string) => {
  const [preview, setPreview] =
    useState<SendVariationFurtherParticularsRequestPreview>();

  const { variation, contract } = useContext(VariationWidgetContext);

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationFurtherParticularsRequestPreviewMutation,
    GenerateSendVariationFurtherParticularsRequestPreviewMutationVariables
  >(generateSendVariationFurtherParticularsRequestPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            reasons,
          },
        },
      });

      if (data) {
        setPreview(data.generateSendVariationFurtherParticularsRequestPreview);
      }
    }, [generatePreview, variation, reasons]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview: preview,
    contract,
  };
};
