import {
  Box,
  BoxProps,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import {
  PrimarySecondaryButtons,
  PrimarySecondaryButtonsProps,
} from "components/PrimarySecondaryButtons";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { AttachmentsDencity } from "containers/Projects/components/Attachments/Attachments.decl";
import { AttachmentsLayout } from "containers/Projects/components/Attachments/components/AttachmentsHeader/AttachmentsHeader";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { Attachment, AttachmentInput } from "generated/graphql";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../DailyDiaryContextProvider";

export type RecordModalFooterProps = {
  addAnotherRecord: boolean;
  showAddAnotherRecord?: boolean;
  attachments?: Attachment[];
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  onAddAnotherRecordChange: (checked: boolean) => void;
} & PrimarySecondaryButtonsProps &
  BoxProps;

export const RecordModalFooter: React.FC<RecordModalFooterProps> = ({
  addAnotherRecord,
  showAddAnotherRecord,
  attachments,
  onAttachmentsUpdated,
  onAddAnotherRecordChange,
  primaryBtnCaption,
  primaryBtnDisabled,
  primaryBtnLoading,
  onPrimaryClick,
  secondaryBtnCaption,
  secondaryBtnLoading,
  onSecondaryClick,
  ...restBoxProps
}) => {
  const { t } = useTranslation();

  const { productInstance } = useContext(DailyDiaryContext);

  const handleAddAnotherRecordChanged = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onAddAnotherRecordChange(checked);
  };

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    onAttachmentsUpdated(attachmentsUpdated);
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
    unloadLocalAttachments,
  } = useAttachments(attachments ?? [], handleAttachmentsUpdated);

  const handleSecondaryClick = () => {
    unloadLocalAttachments();
    onSecondaryClick();
  };

  const attachmentsLoading = useMemo(
    () => allAttachments.some((attach) => attach.loading),
    [allAttachments]
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  return (
    <>
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        contractTimezone={productInstance?.contract.timeZone}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <Stack spacing={4} mt={4}>
        <Attachments
          editMode
          showTabs={false}
          dencity={AttachmentsDencity.Compact}
          defaultLayout={AttachmentsLayout.List}
          attachments={allAttachments}
          onAttachmentsAdd={addAttachments}
          onAttachmentRemove={removeAttachment}
          onAttachmentUpdate={updateAttachment}
          onAttachmentClick={handleAttachmentClick}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent={showAddAnotherRecord ? "space-between" : "flex-end"}
          {...restBoxProps}
        >
          {showAddAnotherRecord && (
            <FormControlLabel
              label={
                <Typography variant="caption">
                  {t("Projects.DailyDiaries.addAnotherRecord")}
                </Typography>
              }
              labelPlacement="end"
              control={
                <Checkbox
                  inputProps={{
                    "aria-label": "add-another-record",
                  }}
                  data-testid="add-another-record-checkbox"
                  onChange={handleAddAnotherRecordChanged}
                  checked={addAnotherRecord}
                />
              }
            />
          )}
          <PrimarySecondaryButtons
            primaryBtnCaption={primaryBtnCaption}
            primaryBtnLoading={primaryBtnLoading}
            primaryBtnDisabled={primaryBtnDisabled || attachmentsLoading}
            secondaryBtnCaption={secondaryBtnCaption}
            secondaryBtnLoading={secondaryBtnLoading}
            onPrimaryClick={onPrimaryClick}
            onSecondaryClick={handleSecondaryClick}
          />
        </Box>
      </Stack>
    </>
  );
};
