import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationErrorDeterminationDisagreementPreviewMutation,
  GenerateRecordVariationErrorDeterminationDisagreementPreviewMutationVariables,
  RecordVariationErrorDeterminationDisagreementPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateRecordVariationErrorDeterminationDisagreementPreviewMutation } from "./DisagreeWithNoticeOfErrorInDeterminationAction.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useRecordDisagreementRegardingNoticeOfErrorInDeterminationModal = (
  remarks: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [preview, setPreview] =
    useState<RecordVariationErrorDeterminationDisagreementPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationErrorDeterminationDisagreementPreviewMutation,
    GenerateRecordVariationErrorDeterminationDisagreementPreviewMutationVariables
  >(
    generateRecordVariationErrorDeterminationDisagreementPreviewMutation,
    {},
    null
  );

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
          },
        },
      });

      if (data) {
        setPreview(
          data.generateRecordVariationErrorDeterminationDisagreementPreview
        );
      }
    }, [generatePreview, variation, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview: preview,
    contract,
  };
};
