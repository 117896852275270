import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationRevisedProposalForProposedVariationRequestPreviewMutation,
  GenerateRecordVariationRevisedProposalForProposedVariationRequestPreviewMutationVariables,
  RecordVariationRevisedProposalForProposedVariationRequestPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateRecordVariationRevisedProposalForProposedVariationRequestPreviewMutation } from "./RequestRevisedProposalAction.query";

export const useRecordRequestRevisedProposalModal = (remarks: string) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [variationPreview, setVariationPreview] =
    useState<RecordVariationRevisedProposalForProposedVariationRequestPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationRevisedProposalForProposedVariationRequestPreviewMutation,
    GenerateRecordVariationRevisedProposalForProposedVariationRequestPreviewMutationVariables
  >(
    generateRecordVariationRevisedProposalForProposedVariationRequestPreviewMutation,
    {},
    null
  );

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateRecordVariationRevisedProposalForProposedVariationRequestPreview
        );
      }
    }, [generatePreview, variation, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview,
    contract,
  };
};
