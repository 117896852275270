import {
  Box,
  CircularProgress,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { SearchBox } from "components/SearchBox";
import { Status } from "decl";
import { User } from "generated/graphql";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItem } from "./ListItem";
import { getUserName } from "helpers/miscelaneous";

export type OwnerSelectFyloutProps = {
  ownerCandidates: User[];
  currentOwner: User;
  loading?: boolean;
  onListItemClick: (clickedUser: User) => void;
};

const FlyoutContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 450px;
`;

export const OwnerSelectFlyout: React.FC<OwnerSelectFyloutProps> = ({
  ownerCandidates,
  currentOwner,
  loading,
  onListItemClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const [localOwnerCandidates, setLocalOwnerCandidates] = useState(
    ownerCandidates ?? []
  );

  const handleSearchBoxChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setSearchQuery(evt.target.value);
    setLocalOwnerCandidates(
      ownerCandidates.filter((ownerCandidate) => {
        const ownerCandidateName = getUserName(ownerCandidate);

        return ownerCandidateName
          .toLowerCase()
          .includes((evt.target.value || "").toLowerCase());
      })
    );
  };

  useEffect(() => {
    setLocalOwnerCandidates(ownerCandidates ?? []);
  }, [ownerCandidates]);

  return (
    <FlyoutContainer display="flex" flexDirection="column" pb={1}>
      <Box px={2} pt={2}>
        <SearchBox
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchBoxChange}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        py={2}
      >
        <Typography
          variant="caption3"
          textTransform="uppercase"
          color={theme.palette.grey[500]}
        >
          {t("common.labels.user")}
        </Typography>
        <Typography
          variant="caption3"
          textTransform="uppercase"
          color={theme.palette.grey[500]}
        >
          {t("common.labels.company")}
        </Typography>
      </Box>
      <Box overflow="auto" maxHeight="300px">
        {loading && (
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress size="20px" />
          </Box>
        )}
        {localOwnerCandidates.map((ownerCandidate) => (
          <ListItem
            key={ownerCandidate.id}
            status={ownerCandidate.status as any as Status}
            userName={getUserName(ownerCandidate)}
            userCompanyName={ownerCandidate.company.registeredName}
            userJobTitle={ownerCandidate.jobTitle}
            selected={ownerCandidate.id === currentOwner.id}
            onSelect={(evt) => {
              evt.stopPropagation();
              onListItemClick(ownerCandidate);
            }}
          />
        ))}
      </Box>
    </FlyoutContainer>
  );
};
