import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { OnMutationDone } from "decl";
import {
  CompEventNoticeRequestQuotationPromptQuery,
  CompEventNoticeRequestQuotationPromptQueryVariables,
  RecordCompEventNoticeRequestQuotationMutation,
  RecordCompEventNoticeRequestQuotationMutationVariables,
  SendCompEventNoticeRequestQuotationMutation,
  SendCompEventNoticeRequestQuotationMutationVariables,
} from "generated/graphql";
import { recordCompEventNoticeRequestQuotationMutation } from "graphql/mutations/recordCompEventNoticeRequestQuotation";
import { sendCompEventNoticeRequestQuotationMutation } from "graphql/mutations/sendCompEventNoticeRequestQuotation";
import { compEventNoticeRequestQuotationPromptQuery } from "graphql/queries/compEventNoticeRequestQuotationPrompt.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";

export const useNotifyAndRequestQuotationCEAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { onChange, compEvent, productInstanceId } = useContext(
    CompEventWidgetContext
  );

  const {
    data: notifyCompEventRequestQuotationPrompt,
    loading: notifyCompEventRequestQuotationPromptLoading,
  } = useGraphQuery<
    CompEventNoticeRequestQuotationPromptQuery,
    CompEventNoticeRequestQuotationPromptQueryVariables
  >(compEventNoticeRequestQuotationPromptQuery, {
    variables: {
      input: {
        productInstanceId: productInstanceId!,
      },
    },
    skip: !productInstanceId,
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
      cache.evict({ id: "ROOT_QUERY", fieldName: "compEventAction" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventHistoryItems",
        args: { compEventId: compEvent?.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, compEvent]
  );

  const [
    sendCENoticeRequestQuotation,
    { loading: sendCENoticeRequestQuotationLoading },
  ] = useGraphMutation<
    SendCompEventNoticeRequestQuotationMutation,
    SendCompEventNoticeRequestQuotationMutationVariables
  >(
    sendCompEventNoticeRequestQuotationMutation,
    {
      update: onUpdated,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("Projects.CompEvents.compEventNoticeAndRequestedQuotation", {
        action: t("common.labels.sent").toLowerCase(),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordCENoticeRequestQuotation,
    { loading: recordCENoticeRequestQuotationLoading },
  ] = useGraphMutation<
    RecordCompEventNoticeRequestQuotationMutation,
    RecordCompEventNoticeRequestQuotationMutationVariables
  >(
    recordCompEventNoticeRequestQuotationMutation,
    {
      update: onUpdated,
    },
    t("Projects.CompEvents.compEventNoticeAndRequestedQuotation", {
      action: t("common.labels.recorded").toLowerCase(),
    })
  );

  return {
    loading: notifyCompEventRequestQuotationPromptLoading,
    actionLoading:
      sendCENoticeRequestQuotationLoading ||
      recordCENoticeRequestQuotationLoading,
    notifyCERequestQuotationPrompt:
      notifyCompEventRequestQuotationPrompt?.compEventNoticeRequestQuotationPrompt,
    sendCENoticeRequestQuotation,
    recordCENoticeRequestQuotation,
  };
};
