import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  AddLookupOptionInput,
  EditLookupOptionInput,
  LookupOption,
  LookupOptionStatus,
} from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import moment from "moment";
import { useContext, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useColumns } from "./LookupCollectionOptions.constants";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StatusOption } from "components/StatusTag/StatusTag";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import {
  dateTimeISOFormat,
  temporaryRowId,
} from "../../../../../../../../constants";
import { sortOptions } from "./LookupCollectionOptions.utils";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { ListBullets } from "phosphor-react";
import { getUserName } from "helpers/miscelaneous";

export type LookupCollectionOptionsProps = {
  collectionName?: string;
  collectionId?: string;
  collectionOptions: LookupOption[];
  loading?: boolean;
  onCollectionOptionStatusChange: (
    collectionOption: LookupOption,
    newStatus: LookupOptionStatus
  ) => Promise<boolean>;
  onCollectionOptionAdd: (input: AddLookupOptionInput) => Promise<boolean>;
  onCollectionOptionEdit: (input: EditLookupOptionInput) => Promise<boolean>;
};

export const LookupCollectionOptions: React.FC<
  LookupCollectionOptionsProps
> = ({
  collectionName,
  collectionId,
  collectionOptions,
  loading,
  onCollectionOptionStatusChange,
  onCollectionOptionAdd,
  onCollectionOptionEdit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser } = useContext(GlobalContext);
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<LookupOptionStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [rows, setRows] = useState<LookupOption[]>(
    sortOptions(collectionOptions)
  );
  const [showOptions, setShowOptions] = useState(true);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(collectionOptions)
  );
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [LookupOptionStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportBindingTypesToExcel = () => {
    const columns = [
      { header: t("common.labels.value"), key: "value", width: 20 },
      {
        header: t("AdminConsole.Products.labels.displayOrder"),
        key: "displayOrder",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
      { header: t("common.labels.createdBy"), key: "creator", width: 20 },
    ];

    const rows = collectionOptions
      .filter(
        (collectionOption) =>
          (selectionModel || []).indexOf(collectionOption.id) >= 0
      )
      .map((collectionOption) => ({
        ...collectionOption,
        creator: getUserName(collectionOption.creator),
        dateCreated: collectionOption.dateCreated
          ? new Date(collectionOption.dateCreated)
          : "",
      }));

    exportToExcel(
      `${collectionName}-${t("AdminConsole.Lookups.collectionOptions")}`,
      columns,
      rows
    );
  };

  const handleRowChangesCommited = useCallback(
    async (
      newRow: GridRowModel<LookupOption>,
      _: GridRowModel<LookupOption>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (newRow.value) {
          const successful = await onCollectionOptionAdd({
            collectionId: collectionId!,
            value: newRow.value,
            displayOrder: newRow.displayOrder,
          });
          if (!successful) {
            setRowModesModel((prevData) => ({
              ...prevData,
              [temporaryRowId]: {
                mode: GridRowModes.Edit,
                fieldToFocus: "name",
              },
            }));
          }
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "name",
            },
          }));
        }
      }

      return newRow;
    },
    [onCollectionOptionAdd, collectionId]
  );

  const handleRowOrderChange: GridEventListener<"rowOrderChange"> = (
    params
  ) => {
    const collectionOption = params.row;
    const newIndex = params.targetIndex + 1;

    if (newIndex !== collectionOption.displayOrder) {
      onCollectionOptionEdit({
        id: collectionOption.id,
        displayOrder: newIndex,
        collectionId: collectionId!,
      });
    }
  };

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onCollectionOptionStatusChange(
          collectionOptions.find(
            (collectionOption) => collectionOption.id === rowId
          )!,
          LookupOptionStatus.Removed
        );
      }
    },
    [onCollectionOptionStatusChange, collectionOptions]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser!,
        creatorId: authenticatedUser!.id,
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        collectionId: collectionId!,
        displayOrder: collectionOptions.length + 1,
        value: "",
        id: temporaryRowId,
        status: LookupOptionStatus.Active,
        isInternal: false,
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "value",
        },
      }));
    });
  };

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const columns = useColumns(
    statusOptions,
    onCollectionOptionStatusChange,
    rowModesModel,
    handleRowSaveClick,
    handleDeleteRow
  );

  useEffect(() => {
    setRows(sortOptions(collectionOptions));
    setRowModesModel(computeGridRowModes(collectionOptions));
  }, [collectionOptions]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Lookups.collectionOptions")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportBindingTypesToExcel}
        icon={
          <ListBullets
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowOptions((state) => !state)}
        collapsed={!showOptions}
      />
      <Collapse in={showOptions}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: LookupOption) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowOrderChange={handleRowOrderChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, LookupOption, any>
            ) => {
              return params.row.status === LookupOptionStatus.Removed
                ? "greyed-out"
                : "";
            }}
            autoHeight
            hideFooter
            checkboxSelection
            disableRowSelectionOnClick
            rowReordering
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
