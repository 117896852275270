import {
  Box,
  Stack,
  StackProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { dateTimeISOFormat } from "../../../../../../constants";
import {
  AuthorizationActionStatus,
  AuthorizationWorkflowAuditTrail,
  User,
} from "generated/graphql";
import { getUserNameOrEmail } from "helpers/miscelaneous";
import moment from "moment";
import { Check, Note, X } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";

type ReviewerListItemProps = {
  reviewer: User;
  auditTrail?: AuthorizationWorkflowAuditTrail;
};

export const ReviewerListItem: React.FC<ReviewerListItemProps & StackProps> = ({
  reviewer,
  auditTrail,
  ...restStackProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { productInstance } = useContext(DailyDiaryContext);

  return (
    <Stack direction="row" alignItems="center" {...restStackProps}>
      <Stack direction="row" alignItems="center" flex={0.9}>
        <Typography variant="p1" color="grey.800">
          {getUserNameOrEmail(reviewer)}
        </Typography>
        {auditTrail && auditTrail.status === AuthorizationActionStatus.Denied && (
          <Tooltip
            arrow
            title={
              <Stack spacing={0.5}>
                <Typography variant="p2" fontWeight={600}>
                  {t("common.labels.remarks")}
                </Typography>
                <Typography variant="p2" fontWeight={400}>
                  {auditTrail.denialReason}
                </Typography>
              </Stack>
            }
          >
            <Box
              pl={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Note size={16} />
            </Box>
          </Tooltip>
        )}
      </Stack>
      {auditTrail &&
        productInstance?.contract.timeZone &&
        auditTrail.status !== AuthorizationActionStatus.Pending && (
          <Tooltip
            title={moment(auditTrail.dateCreated)
              .tz(productInstance?.contract.timeZone)
              .format(dateTimeISOFormat)}
          >
            <Box flex={0.1} display="flex" alignItems="center">
              {auditTrail.status === AuthorizationActionStatus.Approved ? (
                <Check size={24} color={theme.palette.success.main} />
              ) : (
                <X size={24} color={theme.palette.error.main} />
              )}
            </Box>
          </Tooltip>
        )}
    </Stack>
  );
};
