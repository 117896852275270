import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import {
  PageContentHeader,
  PageContentHeaderSize,
} from "components/PageContentHeader/PageContentHeader";
import moment from "moment";
import { HeaderLoadingContainer } from "containers/AdminConsole/containers/Companies/components/HeaderLoadingContainer";
import { useNavigateBack } from "hooks/useNavigateBack";
import { dateTimeISOFormat } from "../../../../../../../constants";

export type ContractTypeDetailsHeaderProps = {
  generalLoading: boolean;
  creator?: string;
  dateCreated?: string;
};

export const ContractTypeDetailsHeader: React.FC<
  ContractTypeDetailsHeaderProps
> = ({ creator, dateCreated, generalLoading }) => {
  const { t } = useTranslation();
  const handleNavigateBack = useNavigateBack();

  return (
    <PageContentHeader size={PageContentHeaderSize.Large}>
      {generalLoading && (
        <HeaderLoadingContainer>
          <CircularProgress />
        </HeaderLoadingContainer>
      )}
      <Box display="flex" alignItems="center">
        <ArrowBackIcon
          onClick={handleNavigateBack}
          style={{ cursor: "pointer" }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          ml={3}
          mr={1}
        >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={handleNavigateBack}>
              <Typography variant="body2">
                {t("AdminConsole.ContractTypes.labels.contractTypes")}
              </Typography>
            </Link>
          </Breadcrumbs>
          <Typography variant="h5">
            {t("AdminConsole.ContractTypes.labels.details")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {creator && dateCreated && (
          <Box display="flex" alignItems="center">
            <Typography variant="body2" mr={0.5}>
              Created by
            </Typography>
            <Typography variant="body2" fontWeight={700} mr={0.5}>
              {creator}
            </Typography>
            <Typography variant="body2" mr={0.5}>
              on
            </Typography>
            <Typography variant="body2" fontWeight={700} mr={0.5}>
              {moment(dateCreated).format(dateTimeISOFormat)}
            </Typography>
          </Box>
        )}
      </Box>
    </PageContentHeader>
  );
};
