import {
  GridColDef,
  GridEditInputCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { dateISOFormat, temporaryRowId } from "../../../../../../constants";
import { ContractKeyDate, ContractKeyDateStatus } from "generated/graphql";

import { TFunction } from "i18next";
import moment from "moment";
import {
  getCommonActions,
  statusComparatorFunction,
} from "helpers/dataGrid.helpers";

export const getColumns = ({
  statusOptions,
  rowModesModel,
  onStatusChange,
  handleDeleteRow,
  handleSaveRow,
  t,
}: {
  statusOptions: StatusOption<ContractKeyDateStatus>[];
  rowModesModel: GridRowModesModel;
  onStatusChange: (
    row: ContractKeyDate,
    newStatus: ContractKeyDateStatus
  ) => void;
  handleSaveRow: (rowId: GridRowId) => void;
  handleDeleteRow: (rowId: GridRowId) => void;
  t: TFunction;
}): GridColDef<ContractKeyDate>[] => {
  return [
    {
      field: "number",
      headerName: t("common.labels.number"),
      flex: 0.2,
      minWidth: 290,
      resizable: true,
      editable: true,
      renderEditCell: (cellParams) => (
        <GridEditInputCell
          {...cellParams}
          type="number"
          inputProps={{ min: 0 }}
        />
      ),
    },
    {
      field: "conditionToBeMet",
      headerName: t("AdminConsole.ContractKeyDates.conditionToBeMet"),
      flex: 0.5,
      minWidth: 90,
      resizable: true,
      editable: true,
      renderEditCell: (cellParams) => (
        <GridEditInputCell
          {...cellParams}
          error={!cellParams.row.conditionToBeMet}
        />
      ),
    },
    {
      field: "keyDate",
      headerName: t("AdminConsole.ContractKeyDates.keyDate"),
      flex: 0.15,
      minWidth: 90,
      resizable: true,
      editable: true,
      valueFormatter: (value?: string) => {
        return value ? moment(value).format(dateISOFormat) : "";
      },
      renderEditCell: (
        cellParams: GridRenderEditCellParams<any, ContractKeyDate, any>
      ) => (
        <GridEditInputCell
          {...cellParams}
          type="date"
          required
          variant="outlined"
          inputProps={{
            min: moment().format(dateISOFormat),
          }}
          error={!cellParams.row.keyDate}
        />
      ),
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 0.15,
      minWidth: 90,
      resizable: true,
      sortComparator: statusComparatorFunction,
      renderCell: (params: GridRenderCellParams<any, ContractKeyDate, any>) => {
        return (
          <StatusTag
            status={params.row.status}
            options={statusOptions}
            disabled={params.row.id === temporaryRowId}
            placement="left-start"
            onChange={(newStatus: StatusOption<ContractKeyDateStatus>) =>
              onStatusChange(params.row, newStatus.id)
            }
          />
        );
      },
    },
    {
      field: "actions",
      flex: 0.05,
      type: "actions",
      getActions: (cellParams) =>
        getCommonActions(
          cellParams,
          rowModesModel,
          handleSaveRow,
          handleDeleteRow
        ),
    },
  ];
};
