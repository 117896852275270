import {
  Box,
  Chip,
  IconButton,
  Stack,
  Typography,
  css,
  styled,
  useTheme,
} from "@mui/material";
import { dateTimeISOFormat } from "../../../../../../constants";
import { DailyDiaryItemRevision } from "generated/graphql";
import moment from "moment";
import { CaretRight } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";

type RevisionListItemProps = {
  revision: DailyDiaryItemRevision;
  isSelected?: boolean;
  onRevisionClick: (revisionId: string) => void;
};

const CurrentRevisionMarker = styled(Chip)(
  ({ theme }) => css`
    background-color: ${theme.palette.success.main};
  `
);

export const RevisionListItem: React.FC<RevisionListItemProps> = ({
  revision,
  isSelected,
  onRevisionClick,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { productInstance } = useContext(DailyDiaryContext);
  const revisionNumberStr = `${Number(revision.number)}`;

  const handleRevisionClick = () => {
    onRevisionClick(revision.id);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Stack>
        <Typography variant="p1" color="grey.800" fontWeight={600} mb={0.5}>
          {t("Projects.DailyDiaries.revisionNo", {
            revisionNo: revisionNumberStr,
          })}
        </Typography>
        <Typography variant="p2" color="grey.800">
          {revision.dateSent && productInstance?.contract.timeZone
            ? moment(revision.dateSent)
                .tz(productInstance?.contract.timeZone)
                .format(dateTimeISOFormat)
            : null}
        </Typography>
      </Stack>
      {isSelected ? (
        <CurrentRevisionMarker
          variant="filled"
          size="small"
          label={
            <Typography
              variant="caption"
              fontWeight={600}
              fontSize="12px"
              lineHeight="15px"
              textTransform="uppercase"
              color={theme.palette.common.white}
            >
              {t("common.labels.current")}
            </Typography>
          }
        />
      ) : (
        <IconButton onClick={handleRevisionClick}>
          <CaretRight size={24} color={theme.palette.grey[800]} />
        </IconButton>
      )}
    </Box>
  );
};
