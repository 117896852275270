import { Stack, Typography } from "@mui/material";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useTranslation } from "react-i18next";
import {
  AttachmentsDencity,
  EnhancedAttachment,
} from "containers/Projects/components/Attachments/Attachments.decl";
import { Contract, VariationProposal } from "generated/graphql";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { VariationProposalAgreementDeterminationReadOnlyModal } from "../../VariationProposalAgreementDeterminationReadOnlyModal/VariationProposalAgreementDeterminationReadOnlyModal";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { VOProposalsAgreementsDeterminationsTable } from "../components/VOProposalsAgreementsDeterminationsTable/VOProposalsAgreementsDeterminationsTable";
import { ReasonSummary } from "containers/Projects/components/ActionModal/ReasonSummary";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";

type ConsentToProposalForProposedVariationSummaryProps = {
  remarks: string;
  proposal: VariationProposal;
  attachments: EnhancedAttachment[];
  allowViewDetails?: boolean;
  contract: Contract;
};

export const ConsentToProposalForProposedVariationSummary: React.FC<
  ConsentToProposalForProposedVariationSummaryProps
> = ({ attachments, remarks, allowViewDetails, proposal, contract }) => {
  const { downloadAttachment } = useAttachments(attachments);
  const { t } = useTranslation();

  const { modalVisibility, toggleModalVisibility } = useBasicModal();

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);
  return (
    <>
      {allowViewDetails && (
        <VariationProposalAgreementDeterminationReadOnlyModal
          open={modalVisibility}
          onClose={toggleModalVisibility}
          item={proposal}
          contract={contract}
        />
      )}
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        contractTimezone={contract.timeZone}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <Stack spacing={3}>
        <Typography
          variant="h4"
          fontSize="18px"
          color="grey.800"
          fontWeight={600}
          mb={2.5}
        >
          {t("Projects.Variations.variationProposal")}
        </Typography>
        <VOProposalsAgreementsDeterminationsTable
          items={[proposal]}
          type="VariationProposal"
          onRowClick={toggleModalVisibility}
          contractCurrency={contract.valueCurrency}
        />
        <ReasonSummary reason={remarks} label={t("common.labels.remarks")} />
        {attachments && attachments.length > 0 && (
          <Stack>
            <Typography variant="p2" color="grey.800" fontWeight={600}>
              {t("Attachments.attachments")}
            </Typography>
            <Attachments
              editMode={false}
              showTabs={false}
              hideTitle
              hideHeader
              dencity={AttachmentsDencity.Compact}
              attachments={attachments}
              onAttachmentClick={handleAttachmentClick}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};
