import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  DraftVariationDetermination,
  RecordVariationDeterminationNoticeMutation,
  RecordVariationDeterminationNoticeMutationVariables,
  SendVariationDeterminationNoticeMutation,
  SendVariationDeterminationNoticeMutationVariables,
  VariationDeterminationNoticePromptQuery,
  VariationDeterminationNoticePromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationDeterminationNoticeMutation,
  sendVariationDeterminationNoticeMutation,
  variationDeterminationNoticePromptQuery,
} from "./NotifyDeterminationOfVariationAction.query";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";

export const useNotifyDeterminationOfVariationAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationDeterminationNoticePromptQuery,
    VariationDeterminationNoticePromptQueryVariables
  >(variationDeterminationNoticePromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationDraftVariationProposals",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationDeterminationNoticePrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [
    sendVariationDetermination,
    { loading: sendVariationDeterminationLoading },
  ] = useGraphMutation<
    SendVariationDeterminationNoticeMutation,
    SendVariationDeterminationNoticeMutationVariables
  >(
    sendVariationDeterminationNoticeMutation,
    { update: onUpdated },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t("Projects.Variations.variationDetermination"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordVariationDetermination,
    { loading: recordVariationDeterminationLoading },
  ] = useGraphMutation<
    RecordVariationDeterminationNoticeMutation,
    RecordVariationDeterminationNoticeMutationVariables
  >(
    recordVariationDeterminationNoticeMutation,
    { update: onUpdated },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.Variations.variationDetermination"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const draftVariationDeterminations = useMemo(
    () =>
      (promptData?.variationDeterminationNoticePrompt
        .draftVariationDeterminations ?? []) as DraftVariationDetermination[],
    [promptData]
  );

  return {
    draftVariationDeterminations,
    loading,
    sendVariationDetermination,
    recordVariationDetermination,
    actionLoading:
      sendVariationDeterminationLoading || recordVariationDeterminationLoading,
  };
};
