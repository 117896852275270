import { Box } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { SendCompEventNoticeRequestQuotationInput } from "generated/graphql";
import { useContext, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotifyAndRequestQuotationCEAction } from "./useNotifyAndRequestQuotationCEAction";
import { emptyRichTextHTML } from "../../../../../../../constants";
import { SendCENoticeAndRequestQuotation } from "./SendCENoticeAndRequestQuotation";
import { RecordCENoticeAndRequestQuotation } from "./RecordCENoticeAndRequestQuotation";
import {
  InstructionProvideQuotationExtraDataForm,
  InstructionProvideQuotationExtraDataType,
} from "containers/Projects/components/CompEvents/CompEventActionModal/components/NotifyAndRequestQuotationCEAction/InstructionProvideQuotationExtraDataForm/InstructionProvideQuotationExtraDataForm";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";

type FormDataType = Pick<
  SendCompEventNoticeRequestQuotationInput,
  "claimTypeId" | "assumptions"
>;

const defaultFormData: FormDataType = {
  claimTypeId: "",
  assumptions: "",
};

export const NotifyAndRequestQuotationCEAction: React.FC<
  CEActionFormProps & { triggersAuthWorkflow?: boolean }
> = ({ apiRef, triggersAuthWorkflow, onClose }) => {
  const { t } = useTranslation();
  const { productInstanceId, contract, regardingId, regardingType } =
    useContext(CompEventWidgetContext);

  const [formData, setFormData] =
    useState<InstructionProvideQuotationExtraDataType>(defaultFormData);
  const [isFormValid, setIsFormValid] = useState(true);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    loading,
    actionLoading,
    sendCENoticeRequestQuotation,
    recordCENoticeRequestQuotation,
    notifyCERequestQuotationPrompt,
  } = useNotifyAndRequestQuotationCEAction(triggersAuthWorkflow);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };
  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCENotice = async () => {
    await sendCENoticeRequestQuotation({
      variables: {
        input: {
          claimTypeId: formData.claimTypeId,
          assumptions:
            formData.assumptions !== emptyRichTextHTML
              ? formData.assumptions
              : undefined,
          productInstanceId,
          regardingId,
          regardingType,
        },
      },
    });

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCENotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCENoticeRequestQuotation({
      variables: {
        input: {
          claimTypeId: formData.claimTypeId,
          assumptions:
            formData.assumptions !== emptyRichTextHTML
              ? formData.assumptions
              : undefined,
          productInstanceId,
          regardingId,
          regardingType,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  return loading || !notifyCERequestQuotationPrompt ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {formData.claimTypeId && sendModalVisibility && (
        <SendCENoticeAndRequestQuotation
          open={sendModalVisibility}
          claimType={
            notifyCERequestQuotationPrompt.compEventTypes.find(
              (claimType) => claimType.id === formData.claimTypeId
            )!
          }
          primaryBtnLoading={actionLoading}
          assumptions={formData.assumptions!}
          onPrimaryClick={handleSendCENotice}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
        />
      )}
      {formData.claimTypeId && recordModalVisibility && (
        <RecordCENoticeAndRequestQuotation
          open={recordModalVisibility}
          claimType={
            notifyCERequestQuotationPrompt.compEventTypes.find(
              (claimType) => claimType.id === formData.claimTypeId
            )!
          }
          assumptions={formData.assumptions!}
          primaryBtnLoading={actionLoading}
          onPrimaryClick={handleRecordCENotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
        />
      )}
      <Box display="flex" flexDirection="column">
        <InstructionProvideQuotationExtraDataForm
          contractId={contract.id}
          onChange={setFormData}
          extraData={formData}
          assumptionsPlaceholder={t(
            "Projects.CompEvents.ActionModal.confirmCEPlaceholder"
          )}
          onFormValidationChange={setIsFormValid}
        />
      </Box>
    </>
  );
};
